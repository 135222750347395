import React from 'react';
import { Grid, IconButton, Drawer, Box } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { ReactComponent as HeyOfficesLogo } from 'src/assets/images/HeyOfficesLogo.svg';
import { TextButton } from 'src/shared/components/TextButton/TextButton';

import { navItems } from '../libs';

type MobileMenuProps = {
    open: boolean;
    onClose: () => void;
}

const Container = styled(Grid)({
    height: '100%',
    minWidth: '430px',
    padding: '0.5rem 40px 6rem',
    backgroundColor: 'black',
});

const ListItemStyled = styled(Box)({
    padding: '10px 0',
    listStyleType: 'none',
    '& a': {
        padding: '3px 8px',
    }
});

export const Menu = ({ open, onClose }: MobileMenuProps) => {
    const location = useLocation();

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Container>
                <Grid container direction="column" spacing={2}>
                    <Grid container item alignItems="center" style={{ filter: 'invert(1)' }}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <RouterLink to="/">
                                    <HeyOfficesLogo height={40} />
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={onClose} color="primary" edge="end">
                                    <HighlightOff fontSize="large" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" alignItems="flex-start" justifyContent="center">
                        <Grid component="ul" style={{ marginLeft: '-8px' }}>
                            {navItems.map(({ title, path, id }) => (
                                <ListItemStyled key={id} component="li">
                                    <TextButton
                                        id={id}
                                        to={path}
                                        buttonText={title}
                                        hovered={!!matchPath(location.pathname, { path })}
                                        size="1.25rem"
                                        inverted
                                    />
                                </ListItemStyled>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Drawer>
    );
};
