import { State } from './store';
import {
    SHORTLIST_PROPERTIES,
    SHORTLIST_OFFICE,
    UNSHORTLIST_OFFICE,
    SHORTLIST_PROPERTY,
    UNSHORTLIST_PROPERTY,
    TOGGLE_SEARCH_OVERLAY,
    TOGGLE_LIST_OVERLAY,
    OPEN_NO_RESULTS_OVERLAY,
    CLOSE_NO_RESULTS_OVERLAY,
    TOGGLE_HOW_IT_WORKS_DIALOG,
    OPEN_CONTACT_OFFICE_DIALOG,
    CLOSE_CONTACT_OFFICE_DIALOG,
    SET_HOVERED_PROPERTY_CARD_ID,
} from './actions';

type Action = {
    type: string;
    payload: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case SHORTLIST_PROPERTIES:
            return { ...state, shortlistedProperties: action.payload };
        case SHORTLIST_OFFICE: {
            const { property, office } = action.payload;
            const stateShortlistedProperty = state.shortlistedProperties.find(({ id }) => id === property.id);

            if (stateShortlistedProperty) {
                const stateShortlistedPropertyIndex = state.shortlistedProperties
                    .findIndex(({ id }) => id === property.id);

                return {
                    ...state,
                    shortlistedProperties: [
                        ...state.shortlistedProperties.slice(0, stateShortlistedPropertyIndex),
                        {
                            ...stateShortlistedProperty,
                            office: [...stateShortlistedProperty.office, office],
                        },
                        ...state.shortlistedProperties.slice(stateShortlistedPropertyIndex + 1),
                    ],
                };
            }

            return {
                ...state,
                shortlistedProperties: [...state.shortlistedProperties, { ...property, office: [office] }],
            };
        }
        case UNSHORTLIST_OFFICE: {
            const { propertyId, officeId } = action.payload;
            const stateShortlistedProperty = state.shortlistedProperties.find(({ id }) => id === propertyId);

            if (stateShortlistedProperty) {
                const stateShortlistedOffice = stateShortlistedProperty.office.find(({ id }) => id === officeId);

                if (stateShortlistedOffice) {
                    const stateShortlistedPropertyIndex = state.shortlistedProperties
                        .findIndex(({ id }) => id === propertyId);
                    const stateShortlistedOfficeIndex = stateShortlistedProperty?.office
                        .findIndex(({ id }) => id === officeId);

                    if (stateShortlistedProperty.office.length === 1) {
                        return {
                            ...state,
                            shortlistedProperties: [
                                ...state.shortlistedProperties.slice(0, stateShortlistedPropertyIndex),
                                ...state.shortlistedProperties.slice(stateShortlistedPropertyIndex + 1),
                            ],
                        };
                    }

                    return {
                        ...state,
                        shortlistedProperties: [
                            ...state.shortlistedProperties.slice(0, stateShortlistedPropertyIndex),
                            {
                                ...stateShortlistedProperty,
                                office: [
                                    ...stateShortlistedProperty.office.slice(0, stateShortlistedOfficeIndex),
                                    ...stateShortlistedProperty.office.slice(stateShortlistedOfficeIndex + 1),
                                ],
                            },
                            ...state.shortlistedProperties.slice(stateShortlistedPropertyIndex + 1),
                        ],
                    };
                }
            }

            return state;
        }
        case SHORTLIST_PROPERTY: {
            const { propertyId } = action.payload;
            const stateShortlistedPropertyIndex = state.shortlistedProperties.findIndex(({ id }) => id === propertyId);

            if (stateShortlistedPropertyIndex !== -1) {
                return {
                    ...state,
                    shortlistedProperties: [
                        ...state.shortlistedProperties.slice(0, stateShortlistedPropertyIndex),
                        action.payload,
                        ...state.shortlistedProperties.slice(stateShortlistedPropertyIndex + 1),
                    ],
                };
            }

            return {
                ...state,
                shortlistedProperties: [...state.shortlistedProperties, action.payload],
            };
        }
        case UNSHORTLIST_PROPERTY: {
            const { propertyId } = action.payload;
            const stateShortlistedPropertyIndex = state.shortlistedProperties.findIndex(({ id }) => id === propertyId);

            if (stateShortlistedPropertyIndex !== -1) {
                return {
                    ...state,
                    shortlistedProperties: [
                        ...state.shortlistedProperties.slice(0, stateShortlistedPropertyIndex),
                        ...state.shortlistedProperties.slice(stateShortlistedPropertyIndex + 1),
                    ],
                };
            }

            return state;
        }
        case TOGGLE_SEARCH_OVERLAY:
            return { ...state, searchOverlay: !state.searchOverlay };
        case TOGGLE_LIST_OVERLAY:
            return { ...state, listOverlay: !state.listOverlay };
        case OPEN_NO_RESULTS_OVERLAY:
            return { ...state, noResultsOverlay: true };
        case CLOSE_NO_RESULTS_OVERLAY:
            return { ...state, noResultsOverlay: false };
        case TOGGLE_HOW_IT_WORKS_DIALOG:
            return { ...state, howItWorksDialog: !state.howItWorksDialog };
        case OPEN_CONTACT_OFFICE_DIALOG:
            return { ...state, contactOfficeDialog: action.payload || true };
        case CLOSE_CONTACT_OFFICE_DIALOG:
            return { ...state, contactOfficeDialog: null };
        case SET_HOVERED_PROPERTY_CARD_ID:
            return { ...state, hoveredPropertyCardId: action.payload };
        default:
            return state;
    }
};

export default reducer;
