import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { ReactComponent as HeyOfficesLogo } from 'src/assets/images/HeyOfficesLogo.svg';
import { BetaTag } from './BetaTag/BetaTag';

type LogoProps = {
    action?: ReactNode;
}

function Logo({ action }: LogoProps) {
    const matchesSmUpScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Grid container alignItems="center">
            <Link to="/">
                <HeyOfficesLogo height={matchesSmUpScreen ? 40 : 32} />
            </Link>
            <BetaTag action={action} />
        </Grid>
    );
}

export default Logo;
