// @ts-nocheck
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { colors, createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows } from './shadows';

const THEMES = {
    HEYOFFICE: 'HEYOFFICE',
};

const baseConfig = {
    direction: 'ltr',
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32
            }
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)'
            }
        }
    }
};

const themeConfigs = [
    {
        name: THEMES.HEYOFFICE,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600]
                    }
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600]
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                light: '#e5e5e5',
                paper: colors.common.white
            },
            primary: {
                main: '#000000',
                light: "#ffffff",
                // dark: "",
                // yellow - ffea00
                // blue - 3344fe
                // Red - f52727
            },
            secondary: {
                main: '#E5EAFF'
            },
            warning: {
                main: "#F7CD46"
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600]
            }
        },
        shadows: softShadows,
        typography: {
            fontFamily: [
                'fluro',
                'serif',
            ].join(','),
        }
    },
];
export enum THEME {
    HEYOFFICE = 'HEYOFFICE',
}

type Settings = {
    direction?: 'ltr' | 'rtl';
    responsiveFontSizes?: boolean;
    theme?: THEME
}

export function createTheme(settings: Settings = {}) {
    let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

    if (!themeConfig) {
        console.warn(new Error(`The theme ${settings.theme} is not valid`));
        [themeConfig] = themeConfigs;
    }

    let theme = createMuiTheme({
        ...baseConfig,
        ...themeConfig,
        direction: settings.direction,
    });

    if (settings.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
}
