import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { Theme, styled } from '@material-ui/core/styles';
import {
    compose,
    display,
    flexbox,
    palette,
    positions,
    sizing,
    spacing,
    typography,
} from '@material-ui/system';

const getTextTransform = (
    uppercase: boolean | undefined,
    lowercase: boolean | undefined,
    capitalize: boolean | undefined,
) => {
    if (uppercase) {
        return 'uppercase';
    }
    if (lowercase) {
        return 'lowercase';
    }
    if (capitalize) {
        return 'capitalize';
    }

    return 'none';
};

type stylesProps = ButtonProps & {
    theme: Theme;
    size?: 'small' | 'medium' | 'large';
    uppercase?: boolean;
    lowercase?: boolean;
    capitalize?: boolean;
    opacity?: number;
    hovered?: boolean;
    noShadow?: boolean;
    fontSize?: string;
}

const styles = ({
    theme,
    size = 'medium',
    uppercase,
    lowercase,
    capitalize,
    opacity,
    hovered,
    noShadow = false,
    fontSize,
}: stylesProps) => ({
    borderRadius: 0,
    fontWeight: 'bold',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    lineHeight: 1.5,
    textTransform: getTextTransform(uppercase, lowercase, capitalize),
    opacity,
    ...(hovered && {
        top: '1px',
        left: '1px',
        backgroundColor: '#ffea00',
    }),
    '&:hover': {
        top: '1px',
        left: '1px',
        backgroundColor: '#ffea00',
    },
    '&:active': {
        top: '3px',
        left: '3px',
        boxShadow: !noShadow ? '0px 0px 0px 0px #ffea00' : 'none',
        backgroundColor: '#ffea00',
    },
    ...(size === 'small' && {
        fontSize: '0.875rem',
        padding: '0.25rem 1rem',
        boxShadow: !noShadow ? '0 0 1px 0 rgba(0, 0, 0, 0.75), 6px 6px 0 0 #ffea00' : 'none',
    }),
    ...(size === 'medium' && {
        fontSize: '1rem',
        padding: '0.5rem 1rem',
        boxShadow: !noShadow ? '0 0 2px 0 rgba(0, 0, 0, 0.75), 9px 9px 0 0 #ffea00' : 'none',
    }),
    ...(size === 'large' && {
        fontSize: '1.6099rem',
        padding: '0.5rem 2rem',
        boxShadow: !noShadow ? '0 0 3px 0 rgba(0, 0, 0, 0.75), 12px 12px 0 0 #ffea00' : 'none',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.1672rem',
        },
    }),
    ...(fontSize && { fontSize }),
});

type ButtonProps = {
    type?: 'button' | 'reset' | 'submit' | undefined;
    className?: string,
    children: React.ReactNode;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSubmit?: (event: React.FormEvent<HTMLButtonElement>) => void;
    id?: string;
    title?: string;
}

const Button = ({
    className,
    children,
    type,
    disabled,
    startIcon,
    endIcon,
    onClick,
    onSubmit,
    id,
    title,
}: ButtonProps) => (
    <MuiButton
        className={className}
        type={type}
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        onSubmit={onSubmit}
        id={id}
        title={title}
    >
        {children}
    </MuiButton>
);

export default styled(Button)(compose(styles, display, flexbox, palette, spacing, sizing, typography, positions));
