import { CircularProgress, Typography, Grid } from '@material-ui/core';
import React from "react";

type LoadingScreenProps = {
    loadingText?: string;
}

export const LoadingScreen = ({ loadingText = "Loading" }: LoadingScreenProps) => (
    <Grid container direction="column" alignContent="center" alignItems="center" spacing={4} style={{ marginTop: "4rem" }}>
        <Grid item>
            <CircularProgress variant="indeterminate" size="72px" />
        </Grid>
        <Grid item>
            <Typography
                variant="h4"
            >
                {loadingText}
            </Typography>
        </Grid>
    </Grid>
);
