import { GA_MEASUREMENT_ID, NODE_ENV } from 'src/config';

function track(...args: unknown[]) {
    if (NODE_ENV !== 'production') {
        return;
    }

    if (!(window as any).gtag) {
        return;
    }

    (window as any).gtag(...args);
}

function pageview(props: { pagePath: string }) {
    track('config', GA_MEASUREMENT_ID, { page_path: props.pagePath });
}

function event(type: string, props: { [k: string]: string }) {
    track('event', type, props);
}

export default {
    pageview,
    event
};
