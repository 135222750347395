import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Button,
    Portal,
    Typography,
    makeStyles
} from '@material-ui/core';

import { getCookie, setCookie, setSessionCookie } from 'src/libs/utils/cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        position: 'fixed',
        bottom: 0,
        left: 0,
        margin: '16px',
        padding: '8px 16px',
        outline: 'none',
        zIndex: 2000
    },
    action: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black
    },
    link: {
        color: '#fff',
    }
}));

function CookiesNotification() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setCookie('consent', 'true');
        setOpen(false);
    };

    useEffect(() => {
        const session = getCookie('hoi');
        const consent = getCookie('consent');

        if (!session) {
            setSessionCookie();
        }

        if (!consent) {
            setOpen(true);
        }
    }, []);

    if (!open) {
        return null;
    }

    return (
        <Portal>
            <div className={classes.root}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="body1" color="inherit">
                            We use Cookies to ensure that we give you the best
                            experience on our website. Read our
                            {' '}
                            <Link
                                className={classes.link}
                                to="/cookie-policy"
                                target="_blank"
                            >
                                Cookie Policy
                            </Link>
                            .
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            className={classes.action}
                        >
                            I Agree
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Portal>
    );
}

export default CookiesNotification;
