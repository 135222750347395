import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid, LinearProgress, styled, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useCreateHeyListingMutation } from 'src/generated/graphql';
import { getSessionCookie } from 'src/libs/utils/cookie';
import { FormStatus } from '../ListDialog';

type FormInput = {
    name: string;
    companyEmail: string;
    message: string;
};

const initialValues = {
    name: "",
    companyEmail: "",
    message: "",
};

export const validationRequirements = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    companyEmail: Yup.string().required("Company Email is reqired").email("Company Email needs to be a valid email address"),
    message: Yup.string(),
});

const FieldInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderRadius: 4,
    },
    "& .MuiInputBase-input": {
        fontSize: "0.9375rem",
        color: theme.palette.primary.main,
    },
    "& .MuiFormLabel-root": {
        fontSize: "0.9375rem",
        fontWeight: "bold",
        color: theme.palette.primary.main,
    }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    padding: "0.5rem 0",
    "& .MuiButton-label": {
        fontSize: "1.1146rem",
        fontWeight: "bold",
        textTransform: "none"
    }
}));

const FormItem = styled(Form)(() => ({
    margin: "3rem 0",
    maxWidth: "300px",
    width: "100%"
}));

const FailMessage = styled(Typography)(({ theme }) => ({
    fontSize: "0.9375rem",
    fontWeight: "bold",
    color: theme.palette.error.main,
}));

type ListingFormProps = {
    onSubmitCompleted: (status: FormStatus) => void;
}

export const ListingForm = ({ onSubmitCompleted }: ListingFormProps) => {
    const [sendFailed, setSendFailed] = useState<boolean>(false);
    const [createHeyListing] = useCreateHeyListingMutation({ ignoreResults: true });

    const onSubmitForm = (values: FormInput) => {
        try {
            createHeyListing({
                variables: {
                    input: {
                        fullName: values.name,
                        companyEmail: values.companyEmail,
                        message: values.message,
                        sessionId: getSessionCookie(),
                    }
                }
            });
            return true;
        } catch (err) {
            return false;
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationRequirements}
            // eslint-disable-next-line consistent-return
            onSubmit={async (values, { setSubmitting }) => {
                setSendFailed(false);
                setSubmitting(true);
                const status = await onSubmitForm(values);
                setSubmitting(false);
                if (status) {
                    return onSubmitCompleted(FormStatus.SUCCESS);
                }
                setSendFailed(true);
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <FormItem>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Field
                                id="listofficepopupname"
                                component={FieldInput}
                                name="name"
                                label="Name"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Field
                                id="listofficepopupcompanyemail"
                                component={FieldInput}
                                name="companyEmail"
                                label="Company Email"
                                variant="outlined"
                                size="small"
                                type="email"
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Field
                                id="listofficepopupmessage"
                                component={FieldInput}
                                label="Message..."
                                name="message"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                size="small"
                            />
                        </Grid>
                        {sendFailed && (
                            <Grid item>
                                <FailMessage>
                                    Sending Failed, please try Submit again in a minute
                                </FailMessage>
                            </Grid>
                        )}
                        <Grid item>
                            {isSubmitting && <LinearProgress />}
                            <SubmitButton
                                id="listofficepopupsubmit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="outlined"
                                fullWidth
                                onClick={submitForm}
                            >
                                Submit
                            </SubmitButton>
                        </Grid>
                    </Grid>
                </FormItem>
            )}
        </Formik>
    );
};
