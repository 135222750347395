import { IMAGE_SERVER } from 'src/config';

export enum MediaPrefix {
    logo = 'logo',
    card = 'card',
    officeCard = 'officeCard',
    default = 'default',
}

export const serveMedia = (file: string, prefix?: MediaPrefix): string => {
    if (prefix && prefix !== MediaPrefix.default) {
        const parts = file.split(".");
        const extension = parts.pop();
        const prefixed = parts.join(".").concat(`_${prefix}.${extension}`);
        return `${IMAGE_SERVER}/${prefixed}`;
    }
    return `${IMAGE_SERVER}/${file}`;
};
