import React, {
    ChangeEvent,
    Fragment,
    KeyboardEvent,
    ReactNode,
    SyntheticEvent,
    useEffect,
    useState,
    useMemo,
} from 'react';
import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks';
import { Box, Card, CardContent, Grid, useMediaQuery } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';
import { FieldArray } from 'formik';

import { Heading } from 'src/components/SearchItemFilter/components/Heading';
import { Filter } from 'src/components/SearchItemFilter/components/Filter';
import { ItemGrid } from 'src/components/SearchItemFilter/components/ItemGrid';
import Button from 'src/shared/components/Button/Button';
import Text from 'src/shared/components/Text/Text';
import { pluralise } from 'src/libs/utils';
import { DropDown } from './DropDown';
import { AmenityGroup, getAreaGroups, Option } from '../lib';
import { FilterLabel } from './FilterLabel';
import withModal from './withModal';

const CardStyled = styled(Card)({
    overflow: 'auto',
});

type LocationsFilterProps = {
    options: Option[];
    selected: string[];
    clearAll: any;
    selectAll: any;
    submitForm?: () => void;
    trigger?: (bindTrigger: { onClick: (event: SyntheticEvent<any, Event>) => void }) => ReactNode;
    size?: 'sm' | 'lg';
}

export const LocationsFilter = ({
    options,
    selected,
    clearAll,
    selectAll,
    submitForm,
    trigger,
    size = 'lg',
}: LocationsFilterProps) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'selectionPopover',
    });
    const matchesSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [displayItems, setDisplayItems] = useState<Option[]>([]);
    const areaGroups = getAreaGroups(displayItems, selected);
    const ModalComponent = useMemo(() => withModal(popupState), [popupState]);

    const onFilter = (term: string) => {
        const filtered = options.filter((item) => item.name.toLowerCase().includes(term.toLowerCase()));
        setDisplayItems(filtered);
    };

    const selectItems = (group: AmenityGroup) => (event: ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        return group.checked ? clearAll(group.items) : selectAll(group.items);
    };

    useEffect(() => {
        setDisplayItems(options);
    }, [options]);

    const handleSubmit = () => {
        if (submitForm) {
            submitForm();
        }
        popupState.close();
    };

    const handleKeyboardSubmit = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handlePopupClose = () => popupState.close();

    return (
        <Fragment>
            {!trigger && (
                <FilterLabel
                    popupState={popupState}
                    title={selected.length > 0 ? `Location [${selected.length}]` : 'Location'}
                />
            )}
            {trigger && trigger(bindTrigger(popupState))}
            <ModalComponent onKeyDown={handleKeyboardSubmit}>
                <CardStyled>
                    <CardContent>
                        <FieldArray
                            name="locations"
                            render={(arrayHelpers) => (
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Heading
                                            title="Location"
                                            options={displayItems}
                                            clearAll={clearAll}
                                            selectAll={selectAll}
                                            size={size}
                                            onClose={handlePopupClose}
                                        >
                                            <Filter placeholder="Filter Locations" onFilterUpdate={onFilter} />
                                        </Heading>
                                    </Grid>
                                    <Grid item>
                                        {(matchesSmScreen || size === 'sm')
                                            && areaGroups.map((areaGroup) => !!areaGroup.items.length && (
                                                <DropDown
                                                    key={areaGroup.title}
                                                    title={areaGroup.title}
                                                    checked={areaGroup.checked}
                                                    onSelect={selectItems(areaGroup)}
                                                    defaultExpanded
                                                >
                                                    <ItemGrid
                                                        options={areaGroup.items}
                                                        fieldName="locations"
                                                        selectedIds={selected}
                                                        arrayHelpers={arrayHelpers}
                                                    />
                                                </DropDown>
                                            ))}
                                        {!matchesSmScreen && size === 'lg' && (
                                            <Grid container wrap="wrap" spacing={3}>
                                                {areaGroups.map((areaGroup) => !!areaGroup.items.length && (
                                                    <Grid key={areaGroup.title} item>
                                                        <DropDown
                                                            title={areaGroup.title}
                                                            checked={areaGroup.checked}
                                                            onSelect={selectItems(areaGroup)}
                                                            width="200px"
                                                            defaultExpanded
                                                        >
                                                            <ItemGrid
                                                                options={areaGroup.items}
                                                                fieldName="locations"
                                                                selectedIds={selected}
                                                                arrayHelpers={arrayHelpers}
                                                            />
                                                        </DropDown>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </CardContent>
                </CardStyled>
                <Box
                    display="flex"
                    flexDirection="column"
                    bgcolor="white"
                    position="sticky"
                    bottom={0}
                    borderTop="1px solid rgba(0, 0, 0, .25)"
                    p="1rem"
                    mt="-0.5rem"
                    zIndex={1}
                >
                    <Box display="flex" alignItems="center">
                        <Button size="small" minWidth="120px" mr="2rem" onClick={handleSubmit} uppercase>Save</Button>
                        <Text size="0.875rem" minWidth="160px" uppercase>
                            {`${selected.length} ${pluralise(selected.length, 'location')} selected`}
                        </Text>
                    </Box>
                    <Text size="0.875rem" mt="1rem">*Pick your favourite spots! Multi-select away…</Text>
                </Box>
            </ModalComponent>
        </Fragment>
    );
};
