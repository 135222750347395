import React from 'react';
import { bindTrigger } from 'material-ui-popup-state/hooks';
import { PopupState } from 'material-ui-popup-state/core';
import { styled } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';
import { ReactComponent as ArrowDown } from '../../../assets/images/home/ArrowDown.svg';

const Label = styled(MuiButton)({
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    '& .MuiButton-endIcon': {
        '& svg': {
            width: '10px',
            height: '10px',
        }
    }
});

interface FilterLabelProps {
    title: string;
    popupState: PopupState
}

export const FilterLabel = ({ title, popupState }: FilterLabelProps) => (
    <Label {...bindTrigger(popupState)} color="primary" endIcon={<ArrowDown />} fullWidth>
        {title}
    </Label>
);
