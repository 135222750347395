import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button as MuiButton, FormHelperText, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useOfficeSearchDataRegionsQuery } from 'src/generated/graphql';
import { Option } from 'src/components/MapSearchBar/lib';
import { LocationsFilter } from '../../MapSearchBar/components/LocationsFilter';
import { FormInput } from './SearchForm';

const LONDON_IDS = ['r222', 'cll2fokv5346931orn2c2ka8ee', 'cll2fp4753615818416956gw73', 'cll2fpkvc362051841ktjaaw0t', 'cll2fpx18347581orn7i6t36ml'];

const ButtonStyled = styled(({ error, ...restProps }) => (
    <MuiButton {...restProps} />
))(({ error }: { error: boolean }) => ({
    border: `1px solid ${error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'}`,
    padding: '14px 10px 14px 14px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.9375rem',
    transition: 'none',
    justifyContent: 'flex-start',
    '&:hover': {
        border: `1px solid ${error ? '#f44336' : 'black'}`,
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& .MuiButton-label': {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '90%',
        textAlign: 'start',
    },
    '& .MuiSvgIcon-root': {
        color: '#546e7a',
        fontSize: '1.5rem',
    },
    '& .MuiButton-endIcon': {
        position: 'absolute',
        right: '0.5rem',
        top: '50%',
        transform: 'translateY(-50%)',
        bottom: 0,
    },
}));

const ErrorText = styled(FormHelperText)({
    margin: '3px 8px 0',
});

interface LocationsSelectProps {
    TriggerIcon?: React.ComponentType,
    Trigger?: any,
}

export const LocationsSelect = ({ TriggerIcon, Trigger }: LocationsSelectProps) => {
    const { values, errors, setFieldValue } = useFormikContext<FormInput>();
    const [locations, setLocations] = useState<Option[]>([]);
    const { data: { officeSearchData } = {} } = useOfficeSearchDataRegionsQuery({
        fetchPolicy: "cache-first",
    });
    const displayedLocations = !!values.locations.length && locations
        .filter(({ id }) => values.locations.includes(id))
        .map(({ name }) => name)
        .join(', ');

    useEffect(() => {
        if (officeSearchData?.regions) {
            setLocations(officeSearchData.regions.filter((r) => LONDON_IDS.includes(r.parentRegionId || '')).map((r) => ({
                id: r.id,
                name: r.name,
                selected: false
            })));
        }
    }, [officeSearchData?.regions]);

    const handleFieldSelect = (fieldName: string) => (options: Option[]) => {
        const optionIds = options.map((option) => option.id);
        const updatedLocations = optionIds.reduce((acc, optionId) => (
            values.locations.includes(optionId) ? acc : [...acc, optionId]
        ), values.locations);
        setFieldValue(fieldName, updatedLocations);
    };

    const handleFieldClear = (fieldName: string) => (options?: Option[]) => {
        if (!options || !options.length) {
            return setFieldValue(fieldName, []);
        }

        const optionIds = options.map((option) => option.id);
        const filteredLocations = values.locations.filter((locationId) => !optionIds.includes(locationId));
        return setFieldValue(fieldName, filteredLocations);
    };

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <LocationsFilter
                    selected={values.locations}
                    options={locations}
                    selectAll={handleFieldSelect('locations')}
                    clearAll={handleFieldClear('locations')}
                    trigger={(bindTrigger: { onClick: (event: SyntheticEvent<any, Event>) => void }) => {
                        const Component = Trigger || ButtonStyled;

                        return (
                            <Component
                                color="primary"
                                endIcon={TriggerIcon ? <TriggerIcon /> : <ArrowDropDownIcon />}
                                {...bindTrigger}
                                error={!!errors.locations}
                                disableRipple
                                fullWidth
                            >
                                {displayedLocations || 'Location'}
                            </Component>
                        );
                    }}
                />
                {!!errors.locations && <ErrorText error>{errors.locations}</ErrorText>}
            </Grid>
        </Grid>
    );
};
