import React from "react";
import { styled, Typography } from "@material-ui/core";

const Content = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    lineHeight: "1.625rem",
    fontSize: "0.9907rem",
    fontWeight: "bold",
    marginTop: "2.5rem",
    color: theme.palette.primary.main,
    maxWidth: "360px",

}));

export const Instructions = ({ content }: { content: string }) => (
    <Content>
        {content}
    </Content>
);
