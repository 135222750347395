import { HEAP_API_KEY } from 'src/config';

const SCRIPT_ID = 'heap';

const getHeapScript = (id: string | number) => `
  window.heap=window.heap||[],
  heap.load=function(e,t){
  window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${id}");
`;

const initialize = () => {
    if (!HEAP_API_KEY) {
        // eslint-disable-next-line no-console
        console.error('You need to provide your Heap Analytics ID.');
        return;
    }

    if (!document) {
        return;
    }

    const hasScript = !!document.getElementById(SCRIPT_ID);

    if (hasScript) {
        return;
    }

    const script = document.createElement('script');

    script.innerHTML = getHeapScript(HEAP_API_KEY);
    script.id = SCRIPT_ID;
    script.async = true;

    document.head.appendChild(script);
};

export default { initialize };
