import React from "react";
import { Grid, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import { Option } from "src/components/MapSearchBar/lib";

type ItemGridProps = {
    options: Option[];
    fieldName: string;
    selectedIds: string[];
    arrayHelpers: any;
    renderLabel?: (option: any) => any;
    onSelect?: (id: string) => void;
    onClear?: (id: string) => void;
}

export const ItemGrid = ({
    options,
    renderLabel,
    fieldName,
    selectedIds,
    arrayHelpers,
    onSelect,
    onClear,
}: ItemGridProps) => {
    const onChangeItem = (option: any) => (e: any) => {
        if (e.target.checked) {
            arrayHelpers.push(option.id);

            if (onSelect) {
                onSelect(option.id);
            }
        } else {
            const idx = selectedIds.indexOf(option.id);
            arrayHelpers.remove(idx);
            if (onClear) {
                onClear(option.id);
            }
        }
    };
    return (
        <Grid container>
            <Grid item>
                <FormGroup>
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.id}
                            control={(
                                <Checkbox
                                    name={fieldName}
                                    value={option.id}
                                    checked={selectedIds.includes(option.id)}
                                    color="primary"
                                    onChange={onChangeItem(option)}
                                />
                            )}
                            label={renderLabel ? renderLabel(option) : option.name}
                        />
                    ))}
                </FormGroup>
            </Grid>
        </Grid>
    );
};
