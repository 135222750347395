import { formatNumber } from './formatNumber';

export const calculateOfficeSize = (desks: number | undefined | null, size: number | undefined | null): string => {
    if (desks && !size) {
        return `Up to ${desks} Desks`;
    }

    if (size && !desks) {
        return `Up to ${formatNumber(size, { style: 'decimal' })} SqFt`;
    }

    if (desks && size) {
        return `Up to ${desks} Desks / ${formatNumber(size, { style: 'decimal' })} SqFt`;
    }

    return 'N/A';
};
