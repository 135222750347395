import React, { lazy, Suspense, Fragment, } from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoadingScreen } from 'src/components/LoadingScreen/LoadingScreen';
import DashboardLayout from './layouts/DashboardLayout';

const routesConfig = [
    {
        exact: true,
        path: '/',
        component: lazy(() => import('src/scenes/Home/Home'))
    },
    {
        layout: DashboardLayout,
        exact: true,
        path: '/search-offices',
        component: lazy(() => import('src/scenes/Map/MapScene'))
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/scenes/Error404View'))
    },
    {
        exact: true,
        path: '/about',
        component: lazy(() => import('src/scenes/About'))
    },
    {
        exact: true,
        path: '/faq',
        component: lazy(() => import('src/scenes/FAQs'))
    },
    {
        exact: true,
        path: '/compare',
        component: lazy(() => import('src/scenes/Compare'))
    },
    {
        exact: true,
        path: ['/privacy-policy', '/privacy-policy/:region'],
        component: lazy(() => import('src/scenes/Policy/PrivacyPolicy'))
    },
    {
        exact: true,
        path: ['/cookie-policy', '/cookie-policy/:region'],
        component: lazy(() => import('src/scenes/Policy/CookiePolicy'))
    },
    {
        exact: true,
        path: ['/terms-and-conditions', '/terms-and-conditions/:region'],
        component: lazy(() => import('src/scenes/Policy/TermsAndConditions'))
    },
    // {
    //     exact: true,
    //     path: '/markers',
    //     component: lazy(() => import('src/scenes/Markers/Markers'))
    // },
];

const renderRoutes = (routes: any) => (routes ? (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route: any) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
) : null);

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
