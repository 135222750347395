type FormatOptions = {
    style?: "currency" | "decimal" | "percent";
    maximumSignificantDigits?: number;
    currency?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number,
    currencyDisplay?: string;
}

export const formatNumber = (number: number | null | undefined, formatOptions?: FormatOptions): string => {
    if (!number) {
        return '-';
    }

    const formatConfig = {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        currencyDisplay: "symbol",
        ...formatOptions
    };

    return new Intl.NumberFormat('en-GB', formatConfig).format(number);
};
