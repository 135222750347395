import { Location } from 'history';
import { TermUnit } from 'src/generated/graphql';
import { convertDurationToDays } from '.';

function max(value: undefined | number) {
    if (value === undefined) {
        return undefined;
    }
    if (value > 0) {
        return value;
    }
    return undefined;
}

export const getLatestCreatedDate = (offices: { createdAt?: string }[]): number | undefined => max(Math.max(...offices.map((office) => new Date(office?.createdAt || "").getTime())));
export const getMaxDesks = (offices: { desks?: number | null | undefined }[]): number | undefined => max(Math.max(...offices.map((office) => office.desks || 0)));
export const getMaxSize = (offices: { size?: number | null | undefined }[]): number | undefined => max(Math.max(...offices.map((office) => office.size || 0)));
export const getMinCost = (offices: { totalCost?: number | null | undefined }[]): number | undefined => max(Math.min(...offices.map((office) => office.totalCost || 0)));
export const getMinTermDays = (offices: { minimumTerm?: number | null | undefined }[]): number | undefined => max(Math.min(...offices.map((office) => office.minimumTerm || 0)));
export const getMinTermIndex = (offices: { minimumTerm?: number | null | undefined; minimumTermUnit: TermUnit }[]): number => {
    const miiin = offices?.map((office) => convertDurationToDays(office?.minimumTerm || 0, office.minimumTermUnit));
    const miin = Math.min(...miiin);
    const indx = miiin.findIndex((i) => i === miin);
    return indx;
};
export const buildPropertyPath = (location: Location, id: string) => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.set('selectedPropertyId', id);
    return `${location.pathname}?${queryParams}`;
};
