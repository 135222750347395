import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import Text from 'src/shared/components/Text/Text';
import Workspace from 'src/assets/images/home/workspace-logo.png';
import JohnyHorne from 'src/assets/images/home/Johny-Horne.jpg';

const SizedAvatar = styled(Avatar)({
    width: '6rem',
    height: '6rem',
});

const Blockquote = styled('blockquote')({
    maxWidth: '600px',
    background: `
        linear-gradient(to right, black 6px, transparent 6px) 0 100%,
        linear-gradient(to left, black 6px, transparent 6px) 100% 0,
        linear-gradient(to bottom, black 6px, transparent 6px) 100% 0,
        linear-gradient(to top, black 6px, transparent 6px) 0 100%
    `,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
    position: 'relative',
    color: 'black',
    padding: '2rem',
    fontWeight: 'bold',
    textAlign: 'justify',
    '&::before, &::after': {
        position: 'absolute',
        fontSize: '4rem',
        lineHeight: 0,
    },
    '&::before': {
        content: '"\\201C"',
        top: '1.5rem',
        left: 0,
    },
    '&::after': {
        content: '"\\201D"',
        bottom: 0,
        right: 0,
    },
});

export const ProviderQuote = () => (
    <Blockquote>
        <Text size="1rem" mb="0.5rem">
            It’s been a pleasure working with HeyOffices
            and we’ve already closed the first lead they sent through - free of charge!
        </Text>
        <Text size="1rem" mb="0.5rem">
            For us, speaking directly to the customer means we can build a long term relationship
            and present our buildings in the best possible light.
        </Text>
        <Text size="1rem" mb="0.5rem">
            HeyOffices enables this by giving us all the customer details,
            what they’re searching for, what they’ve favourited, meaning my team and I can be fully prepared
            and deliver the best outcome for them straight away.
        </Text>
        <Text size="1rem" mb="1rem">Keep sending the leads our way!</Text>
        <Grid container spacing={2}>
            <Grid item>
                <SizedAvatar src={JohnyHorne} alt="Jonny Horne, Enquiries Manager" />
            </Grid>
            <Grid item>
                <Text size="1rem" mb="1rem" bold>Jonny Horne, Enquiries Manager</Text>
                <img src={Workspace} alt="Workspace" width={116} height={64} />
            </Grid>
        </Grid>
    </Blockquote>
);
