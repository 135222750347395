import React, { ReactNode } from "react";
import { Grid, Button, Typography, Box, IconButton, useMediaQuery } from '@material-ui/core';
import { Theme, styled } from '@material-ui/core/styles';
import { HighlightOff } from '@material-ui/icons';

import { Option } from 'src/components/MapSearchBar/lib';

const Container = styled(Grid)({
    paddingRight: '3rem',
    position: 'relative',
});

const StyledButton = styled(Button)({
    fontSize: '0.75rem',
    minWidth: 'unset',
});

type HeadingProps = {
    title: string;
    options: Option[];
    clearAll: any
    selectAll: any
    children?: ReactNode;
    size?: 'sm' | 'lg';
    onClose?: () => void;
}

export const Heading = ({
    title,
    options,
    clearAll,
    selectAll,
    children,
    size,
    onClose,
}: HeadingProps) => {
    const matchesSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const handleClear = () => {
        clearAll();
    };

    const handleSelectAll = () => {
        selectAll(options);
    };

    if (matchesSmScreen || size === 'sm') {
        return (
            <Container container spacing={1} direction="column">
                <Grid item>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item style={{ flex: '1 0 auto' }}>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                        <Grid item>
                            <StyledButton variant="text" onClick={handleSelectAll} color="primary">Select All</StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton variant="text" onClick={handleClear} color="primary">Clear</StyledButton>
                        </Grid>
                    </Grid>
                </Grid>
                {children && <Grid item>{children}</Grid>}
                <Box position="absolute" top={0} right={0}>
                    <IconButton color="primary" onClick={onClose}>
                        <HighlightOff />
                    </IconButton>
                </Box>
            </Container>
        );
    }

    return (
        <Container container spacing={1} alignItems="center" justifyContent="flex-start">
            <Grid item style={{ flex: '1 0 auto' }}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            {children && <Grid item>{children}</Grid>}
            <Grid item>
                <StyledButton variant="text" onClick={handleSelectAll} color="primary">Select All</StyledButton>
            </Grid>
            <Grid item>
                <StyledButton variant="text" onClick={handleClear} color="primary">Clear</StyledButton>
            </Grid>
            <Box position="absolute" top={0} right={0}>
                <IconButton color="primary" onClick={onClose} edge="end">
                    <HighlightOff />
                </IconButton>
            </Box>
        </Container>
    );
};
