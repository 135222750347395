import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from 'src/services/apollo';
import Heap from 'src/services/heap';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

if (window.location.hostname === 'heyoffices.com') {
    Heap.initialize();
}

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

type Analytics = {
    id: string;
    name: string;
    value: number;
};

function sendToAnalytics({ id, name, value }: Analytics) {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (typeof gtag === "function") {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        gtag('event', name, {
            event_category: 'Web Vitals',
            value: Math.round(name === 'CLS' ? value * 1000 : value),
            event_label: id,
            non_interaction: true,
        });
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(sendToAnalytics);
