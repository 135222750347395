// @ts-ignore
const createPoint = (lat: number, lng: number) => new window.google.maps.LatLng(lat, lng);

export const shortenLocation = (location: string, itemsToShow: number = 3): string => {
    const locations = location?.split(",");
    let displayLocation = location;
    if (locations && locations.length > itemsToShow) {
        displayLocation = locations.slice(0, itemsToShow).join(",").concat(", ...");
    }
    return displayLocation;
};

export const positionMap = (map: any, points: { lat: number, lng: number }[], fullscreen: boolean = true) => {
    if (points.length > 1) {
        // @ts-ignore
        const bounds = new window.google.maps.LatLngBounds();
        points.forEach((point) => {
            if (point.lat && point.lng) {
                // @ts-ignore
                const location = createPoint(point.lat, point.lng);
                bounds.extend(location);
            }
        });
        map.fitBounds(bounds, fullscreen ? {} : { left: 720 });
    } else if (points && points.length === 1) {
        if (points[0].lat && points[0].lng) {
            // @ts-ignore
            const location = createPoint(points[0].lat, points[0].lng);
            map.setCenter(location);
            map.setZoom(14);
        }
    } else {
        map.panTo({ lat: 51.509865, lng: -0.118092 });
    }
};

export const centerMap = (map: any, lat: number, lng: number, zoom: number = map.getZoom()) => {
    const location = createPoint(lat, lng);
    map.setCenter(location);
    map.setZoom(zoom);
};

export const inRange = (value: number, min: number, max: number): boolean => {
    if (!value) {
        return false;
    }
    return value <= max && value >= min;
};

export const getBounds = (
    map: any,
    {
        top = 0,
        right = 0,
        left = 0,
        bottom = 0,
    }: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    }
) => {
    const sw = map.getBounds().getSouthWest();
    const ne = map.getBounds().getNorthEast();
    const topRight = map.getProjection().fromLatLngToPoint(ne);
    const bottomLeft = map.getProjection().fromLatLngToPoint(sw);
    const scale = 2 ** map.getZoom();
    // @ts-ignore
    const googleMaps = window.google.maps;

    const swToPoint = map.getProjection().fromLatLngToPoint(sw);
    const swPoint = new googleMaps.Point(
        ((swToPoint.x - bottomLeft.x) * scale) + left,
        ((swToPoint.y - topRight.y) * scale) - bottom,
    );
    const swMapPoint = new googleMaps.Point(swPoint.x / scale + bottomLeft.x, swPoint.y / scale + topRight.y);
    const pointOne = map.getProjection().fromPointToLatLng(swMapPoint);

    const neToPoint = map.getProjection().fromLatLngToPoint(ne);
    const nePoint = new googleMaps.Point(
        ((neToPoint.x - bottomLeft.x) * scale) - right,
        ((neToPoint.y - topRight.y) * scale) + top,
    );
    const neMapPoint = new googleMaps.Point(nePoint.x / scale + bottomLeft.x, nePoint.y / scale + topRight.y);
    const pointTwo = map.getProjection().fromPointToLatLng(neMapPoint);

    return new googleMaps.LatLngBounds(pointOne, pointTwo);
};

export const getLatLngBounds = (bounds: any) => ({
    // @ts-ignore
    neLat: bounds.getNorthEast().lat(),
    // @ts-ignore
    neLng: bounds.getNorthEast().lng(),
    // @ts-ignore
    swLat: bounds.getSouthWest().lat(),
    // @ts-ignore
    swLng: bounds.getSouthWest().lng(),
});
