export const SHORTLIST_PROPERTIES = 'SHORTLIST_PROPERTIES';
export const SHORTLIST_OFFICE = 'SHORTLIST_OFFICE';
export const UNSHORTLIST_OFFICE = 'UNSHORTLIST_OFFICE';
export const SHORTLIST_PROPERTY = 'SHORTLIST_PROPERTY';
export const UNSHORTLIST_PROPERTY = 'UNSHORTLIST_PROPERTY';
export const TOGGLE_SEARCH_OVERLAY = 'TOGGLE_SEARCH_OVERLAY';
export const TOGGLE_LIST_OVERLAY = 'TOGGLE_LIST_OVERLAY';
export const OPEN_NO_RESULTS_OVERLAY = 'OPEN_NO_RESULTS_OVERLAY';
export const CLOSE_NO_RESULTS_OVERLAY = 'CLOSE_NO_RESULTS_OVERLAY';
export const TOGGLE_HOW_IT_WORKS_DIALOG = 'TOGGLE_HOW_IT_WORKS_DIALOG';
export const OPEN_CONTACT_OFFICE_DIALOG = 'OPEN_CONTACT_OFFICE_DIALOG';
export const CLOSE_CONTACT_OFFICE_DIALOG = 'CLOSE_CONTACT_OFFICE_DIALOG';
export const SET_HOVERED_PROPERTY_CARD_ID = 'SET_HOVERED_PROPERTY_CARD_ID';

const createAction = (type: string, payload?: any) => ({ type, payload });

export const shortlistProperties = (payload: any) => createAction(SHORTLIST_PROPERTIES, payload);

export const shortlistOffice = (payload: any) => createAction(SHORTLIST_OFFICE, payload);

export const unshortlistOffice = (payload: any) => createAction(UNSHORTLIST_OFFICE, payload);

export const shortlistProperty = (payload: any) => createAction(SHORTLIST_PROPERTY, payload);

export const unshortlistProperty = (payload: any) => createAction(UNSHORTLIST_PROPERTY, payload);

export const toggleSearchOverlay = () => createAction(TOGGLE_SEARCH_OVERLAY);

export const toggleListOverlay = () => createAction(TOGGLE_LIST_OVERLAY);

export const openNoResultsOverlay = () => createAction(OPEN_NO_RESULTS_OVERLAY);

export const closeNoResultsOverlay = () => createAction(CLOSE_NO_RESULTS_OVERLAY);

export const toggleHowItWorksDialog = () => createAction(TOGGLE_HOW_IT_WORKS_DIALOG);

export const openContactOfficeDialog = (payload?: any) => createAction(OPEN_CONTACT_OFFICE_DIALOG, payload);

export const closeContactOfficeDialog = () => createAction(CLOSE_CONTACT_OFFICE_DIALOG);

export const setHoveredPropertyCardId = (id: string | null) => createAction(SET_HOVERED_PROPERTY_CARD_ID, id);
