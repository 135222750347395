import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { GRAPHQL_URL } from 'src/config';

export const client = new ApolloClient({
    link: createHttpLink({
        uri: GRAPHQL_URL,
        credentials: 'include',
        headers: {
            brokeroverride: "DIRECTACCESS"
        }
    }),
    cache: new InMemoryCache()
});
