import { TermUnit } from 'src/generated/graphql';
import { pluralise } from './pluralise';

const MONTH = 30;
const YEAR = 365;

export const convertDurationToDays = (amount: number, unit: TermUnit): number => {
    if (unit === TermUnit.Month) {
        return amount * MONTH;
    }
    if (unit === TermUnit.Year) {
        return amount * YEAR;
    }
    return amount;
};

export const convertDurationToMonths = (amount: number, unit: TermUnit): number => {
    if (unit === TermUnit.Day) {
        return amount / MONTH;
    }
    if (unit === TermUnit.Year) {
        return amount * 12;
    }
    return amount;
};

export const convertDaysToDuration = (amount: number, unit: TermUnit): number => {
    if (unit === TermUnit.Month) {
        return Math.round(amount / MONTH);
    }
    if (unit === TermUnit.Year) {
        return Math.round(amount / YEAR);
    }
    return Math.round(amount);
};

export const pluraliseTermUnit = (amount: number, unit: TermUnit): string => {
    if (unit === TermUnit.Month) {
        return pluralise(amount, "Month", "Months");
    }
    if (unit === TermUnit.Year) {
        return pluralise(amount, "Year", "Years");
    }
    return pluralise(amount, "Day", "Days");
};

export const formatTerm = (term: number | null | undefined, termUnit: TermUnit): string => {
    if (!term) {
        return "-";
    }
    // If it's years then display as years
    if (termUnit === TermUnit.Year) {
        return `${term} ${pluraliseTermUnit(term, TermUnit.Year)}`;
    }

    const days = convertDurationToDays(term, termUnit);

    // Under 1 month, display as days
    if (days < MONTH) {
        return `${days} ${pluraliseTermUnit(days, TermUnit.Day)}`;
    }

    // Under 2 years, display as months
    if (days <= (YEAR * 2)) {
        const months = convertDaysToDuration(days, TermUnit.Month);
        return `${months} ${pluraliseTermUnit(months, TermUnit.Month)}`;
    }

    // Over 2 years, display as years
    if (days > (YEAR * 2)) {
        // If we origionally had the number in months then use that to convert to years as going from days to years would not be accurate:
        // Example:
        // 36 Months, converted to days = 36 * 30 = 1080.  1080 / 365 to get years = 2.95...
        // 36 Months, leave as months, 36 / 12 to get years = 3
        if (termUnit === TermUnit.Month) {
            const years = convertDaysToDuration(days, TermUnit.Year);
            return `${years} ${pluraliseTermUnit(years, TermUnit.Year)}`;
        }
        const years = convertDaysToDuration(days, TermUnit.Year);
        return `${years} ${pluraliseTermUnit(years, TermUnit.Year)}`;
    }

    return "-";
};
