import { OfficeItemFragment, OfficeType, SizeUnit, TermUnit } from 'src/generated/graphql';
import * as Yup from 'yup';
import qs from 'qs';
import { getSessionCookie } from 'src/libs/utils/cookie';
import { UK_REGION_ID } from 'src/config';
import { ProviderList } from './MapSearchBar';

export const londonAreas = [
    {
        title: 'East London',
        locations: [
            { id: 'r28' }, // 'Shoreditch'
            { id: 'r16' }, // 'Hackney, Haggerston & Dalston'
            { id: 'r3' }, // 'Aldgate & Whitechapel'
            { id: 'r32' }, // 'Stratford'
            { id: 'r9' }, // 'Canary Wharf'
        ],
    },
    {
        title: 'Central London',
        locations: [
            { id: 'r29' }, // 'Soho'
            { id: 'r12' }, // 'Covent Garden'
            { id: 'r15' }, // 'Fitzrovia'
            { id: 'r11' }, // 'Clerkenwell & Farringdon'
            { id: 'r18' }, // 'Holborn & Chancery Lane'
            { id: 'r24' }, // 'Mayfair & St James's'
            { id: 'r36' }, // 'Victoria'
        ],
    },
    {
        title: 'North London',
        locations: [
            { id: 'r20' }, // 'King's Cross'
            { id: 'r8' }, // 'Camden'
            { id: 'r4' }, // 'Angel & Islington'
            { id: 'r14' }, // 'Euston'
        ],
    },
    {
        title: 'South London',
        locations: [
            { id: 'r22' }, // 'London Bridge'
            { id: 'r30' }, // 'Southbank & Borough'
            { id: 'r37' }, // 'Waterloo'
            { id: 'r35' }, // 'Vauxhall & Kennington'
            { id: 'r6' }, // 'Battersea, Clapham & Brixton'
            { id: 'r27' }, // 'Peckham & Dulwich'
            { id: 'r38' }, // 'Wimbledon'
        ],
    },
    {
        title: 'West London',
        locations: [
            { id: 'r23' }, // 'Marylebone'
            { id: 'r26' }, // 'Paddington'
            { id: 'r25' }, // 'Notting Hill'
            { id: 'r7' }, // 'Belgravia & Knightsbridge'
            { id: 'r19' }, // 'Kensington & Fulham'
            { id: 'r17' }, // 'Hammersmith'
            { id: 'r10' }, // 'Chiswick',
            { id: 'r13' }, // 'Ealing'
            { id: 'r34' }, // 'Uxbridge'
        ],
    },
    {
        title: 'City of London',
        locations: [
            { id: 'r21' }, // 'Liverpool Street'
            { id: 'r5' }, // 'Bank'
            { id: 'r31' }, // 'St. Paul's'
            { id: 'r33' }, // 'Tower Hill`
        ],
    },
];

export type Option = {
    id: string;
    name: string;
    selected: boolean;
}

export type ProviderOption = {
    id: string;
    name: string;
    officeCount?: number | null;
    propertiesCount?: number | null;
    selected: boolean;
}

export type Range = {
    from?: number;
    to?: number;
};

type RadiusPoint = {
    lat: number;
    lng: number;
};

export type OfficeFilters = {
    desks: Range | undefined;
    size?: Range;
    sizeUnit?: SizeUnit;
    budget?: Range;
    location?: string[];
    region?: string[];
    officeType?: string[];
    amenity?: string[];
    minimumTerm?: Range;
    minimumTermUnit?: TermUnit;
    radius?: number;
    radiusFrom?: RadiusPoint;
    provider?: string[];
    searchString?: string;
    showUnavailable?: boolean;
    sessionId?: string
};

export type OfficeFiltersForFavorites = {
    desksFrom?: number;
    desksTo?: number;
    budgetFrom?: number;
    budgetTo?: number;
    budgetOption: string;
    locations?: string[];
    sessionId?: string
};

export const popoverUpdate = (event: React.ChangeEvent<HTMLInputElement>, options: Option[], setLocation: React.Dispatch<React.SetStateAction<Option[]>>) => {
    const updated = options.map((option) => {
        if (option.id === event.target.value) {
            return {
                ...option,
                selected: event.target.checked
            };
        }
        return option;
    });
    setLocation(updated);
};

export const popoverClear = (options: Option[], setLocation: React.Dispatch<React.SetStateAction<Option[]>>) => {
    const updated = options.map((option) => ({
        ...option,
        selected: false
    }));
    setLocation(updated);
};

export const popoverSelectAll = (options: Option[], setLocation: React.Dispatch<React.SetStateAction<Option[]>>) => {
    const updated = options.map((option) => ({
        ...option,
        selected: true
    }));
    setLocation(updated);
};

export const defaultValues: MapSearch = {
    locations: [],
    amenities: [],
    providers: [],
    officeSize: {
        option: "desks",
        from: "",
        to: "",
    },
    budget: {
        option: "monthly",
        from: "",
        to: "",
    },
    showUnavailable: false,
};

export type MapSearch = {
    locations: string[];
    amenities: string[];
    providers: string[],
    officeSize: {
        option: "desks" | "size";
        from: string;
        to: string;
    },
    budget: {
        option: "monthly" | "yearly";
        from: string;
        to: string;
    },
    showUnavailable: boolean;
    selectedPropertyId?: string;
}

export const validationRequirements = Yup.object().shape({
    locations: Yup.array(Yup.string()),
    amenities: Yup.array(Yup.string()),
    showUnavailable: Yup.boolean(),
    officeSize: Yup.object({
        option: Yup.string(),
        from: Yup.number().notRequired().integer().min(0),
        to: Yup.number().notRequired().integer().min(0)
            .test(
                'shouldBeGreaterIfSet',
                'To should be greated than From if either are set',
                function validator(value) {
                    const { from } = this.parent;
                    if (!from || !value) {
                        return true;
                    }
                    if (value === 0 && from === 0) {
                        return true;
                    }
                    return value > from;
                }
            )
    }),
    budget: Yup.object({
        option: Yup.string(),
        from: Yup.number().notRequired().integer().min(0),
        to: Yup.number().notRequired().integer().min(0)
            .test(
                'shouldBeGreaterIfSet',
                'To should be greated than From if either are set',
                function validator(value) {
                    const { from } = this.parent;
                    if (!from || !value) {
                        return true;
                    }
                    if (value === 0 && from === 0) {
                        return true;
                    }
                    return value > from;
                }
            )
    }),
});

export const parseValuesForFilter = (values: MapSearch): OfficeFilters => {
    const variables: OfficeFilters = {
        desks: undefined,
        size: undefined,
        sizeUnit: SizeUnit.SquareFeet,
        budget: undefined,
        location: undefined,
        region: [UK_REGION_ID],
        officeType: [OfficeType.Managed, OfficeType.Private],
        amenity: undefined,
        minimumTerm: undefined,
        minimumTermUnit: undefined,
        radius: undefined,
        radiusFrom: undefined,
        provider: undefined,
        searchString: undefined,
        showUnavailable: undefined,
    };
    if (values.locations?.length > 0) {
        variables.location = values.locations;
    }
    if (values.amenities?.length > 0) {
        variables.amenity = values.amenities;
    }
    if (values.providers?.length > 0) {
        variables.provider = values.providers;
    }
    variables.showUnavailable = values.showUnavailable;

    if (values.officeSize && (values.officeSize.from || values.officeSize.to)) {
        if (values.officeSize.option === "desks") {
            variables.desks = {
                from: parseInt(values.officeSize.from, 10) || undefined,
                to: parseInt(values.officeSize.to, 10) || undefined,
            };
        } else {
            variables.size = {
                from: parseInt(values.officeSize.from, 10) || undefined,
                to: parseInt(values.officeSize.to, 10) || undefined,
            };
        }
    }
    if (values.budget && values.budget.to) {
        variables.budget = {
            from: values.budget.option === "yearly" ? Math.round(parseInt(values.budget.from, 10) / 12) : parseInt(values.budget.from, 10),
            to: values.budget.option === "yearly" ? Math.round(parseInt(values.budget.to, 10) / 12) : parseInt(values.budget.to, 10),
        };
    }

    variables.sessionId = getSessionCookie();
    return variables;
};

export const parseFiltersForFavourites = (values: MapSearch, locations:Option[]): OfficeFiltersForFavorites => ({
    locations: locations.filter((location) => values.locations.includes(location.id)).map((l) => l.name),
    desksFrom: values.officeSize.option === 'desks'
        ? Number.parseInt(values.officeSize.from || "0", 10)
        : 0,
    desksTo: values.officeSize.option === 'desks'
        ? Number.parseInt(values.officeSize.to || "0", 10)
        : 0,
    budgetFrom: Number.parseInt(values.budget.from || "0", 10),
    budgetTo: Number.parseInt(values.budget.to || "0", 10),
    budgetOption: values.budget.option,
    sessionId: getSessionCookie(),
});

export const queryDecoder = (str: string, decoder: any, charset: string): any => {
    const strWithoutPlus = str.replace(/\+/g, ' ');

    if (charset === 'iso-8859-1') {
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }

    if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
    }

    const keywords: { [key: string]: true | false | null | undefined } = {
        true: true,
        false: false,
        null: null,
        undefined,
    };

    if (str in keywords) {
        return keywords[str];
    }

    try {
        return decodeURIComponent(strWithoutPlus);
    } catch (e) {
        return strWithoutPlus;
    }
};

const convertToArray = (value: any): string[] => {
    if (Array.isArray(value)) return value;

    return Object.values(value);
};

export const getInitialValuesFromSearch = (search: string) => {
    const {
        locations,
        amenities,
        providers,
        officeSize,
        budget,
        showUnavailable,
        ...restParsed
    }: any = qs.parse(search, { ignoreQueryPrefix: true, decoder: queryDecoder }) as MapSearch;

    return {
        locations: locations ? convertToArray(locations) : defaultValues.locations,
        amenities: amenities ? convertToArray(amenities) : defaultValues.amenities,
        providers: providers ? convertToArray(providers) : defaultValues.providers,
        officeSize: {
            from: officeSize?.from || officeSize?.from === 0 ? officeSize?.from : defaultValues.officeSize.from,
            to: officeSize?.to || officeSize?.to === 0 ? officeSize?.to : defaultValues.officeSize.to,
            option: officeSize?.option || defaultValues.officeSize.option,
        },
        budget: {
            from: budget?.from || budget?.from === 0 ? budget?.from : defaultValues.budget.from,
            to: budget?.to || budget?.to === 0 ? budget?.to : defaultValues.budget.to,
            option: budget?.option || defaultValues.budget.option,
        },
        showUnavailable: showUnavailable || defaultValues.showUnavailable,
        ...restParsed,
    };
};

export const getFiltersChanged = (values: MapSearch, initialValues: MapSearch) => {
    if (initialValues.locations.length !== values.locations.length
        || !initialValues.locations.every((location) => values.locations.includes(location))
    ) {
        return true;
    }

    if (initialValues.officeSize.option !== values.officeSize.option
        || !Object.is(Number.parseFloat(initialValues.officeSize.from), Number.parseFloat(values.officeSize.from))
        || !Object.is(Number.parseFloat(initialValues.officeSize.to), Number.parseFloat(values.officeSize.to))
    ) {
        return true;
    }

    if (initialValues.budget.option !== values.budget.option
        || !Object.is(Number.parseFloat(initialValues.budget.from), Number.parseFloat(values.budget.from))
        || !Object.is(Number.parseFloat(initialValues.budget.to), Number.parseFloat(values.budget.to))
    ) {
        return true;
    }

    if (initialValues.amenities.length !== values.amenities.length
        || !initialValues.amenities.every((location) => values.amenities.includes(location))
    ) {
        return true;
    }

    if (initialValues.providers.length !== values.providers.length
        || !initialValues.providers.every((provider) => values.providers.includes(provider))
    ) {
        return true;
    }

    return false;
};

export type AmenityGroup = {
    title: string;
    items: Option[];
    checked: boolean;
}

export const getAmenityGroup = (
    title: string,
    items: Option[],
    filterFn: (option: Option) => boolean,
    selected: string[],
): AmenityGroup => {
    const filtered = items.filter(filterFn);
    const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
    const checked = sorted.every(({ id }) => selected.includes(id));

    return {
        title,
        checked,
        items: sorted,
    };
};

export type AreaGroup = {
    title: string;
    items: Option[];
    checked: boolean;
}

export const getAreaGroups = (options: Option[], selected: string[]): AreaGroup[] => (
    londonAreas.map((area) => ({
        ...area,
        title: area.title,
        items: area.locations.reduce((acc: any, { id }: { id: string }) => {
            const foundOption = options.find((option) => option.id === id);
            return foundOption ? [...acc, foundOption] : acc;
        }, []),
        checked: area.locations.every(({ id }) => selected.includes(id)),
    }))
);

export const getOfficeSizeFilterHelperText = ({ option, from, to }: MapSearch['officeSize']) => {
    if (option === 'desks' && (from !== '' || to !== '')) {
        const fromString = from !== '' ? `${Intl.NumberFormat().format(Math.round(+from * 50))}` : '';
        const hyphenString = from !== '' && to !== '' ? ' - ' : '';
        const toString = to !== '' ? `${Intl.NumberFormat().format(Math.round(+to * 50))}` : '';
        return `*this would be around ${fromString}${hyphenString}${toString} sq ft.`;
    }

    if (option === 'size' && (from !== '' || to !== '')) {
        const fromString = from !== '' ? `${Intl.NumberFormat().format(Math.round(+from / 50))}` : '';
        const hyphenString = from !== '' && to !== '' ? ' - ' : '';
        const toString = to !== '' ? `${Intl.NumberFormat().format(Math.round(+to / 50))}` : '';
        return `*this would be around ${fromString}${hyphenString}${toString} desks.`;
    }

    return '';
};

export const mapOfficeProperties = (office: OfficeItemFragment) => ({
    id: office.id,
    name: office.name,
    officeType: office.officeType,
    floor: office.floor,
    desks: office.desks,
    size: office.size,
    price: office.price,
    groundRent: office.groundRent,
    rates: office.rates,
    totalCost: office.totalCost,
    availableFrom: office.availableFrom,
    leadTime: office.leadTime,
    leadTimeUnit: office.leadTimeUnit,
    minimumTerm: office.minimumTerm,
    minimumTermUnit: office.minimumTermUnit,
    featureImage: office.featureImage,
    floorplan: office.floorplan,
    image: office.image,
    tour: office.tour,
});

export const getProvidersFormValues = (providers: ProviderList[], unselectedProviders: string[]): string[] => providers
    .filter((provider) => !unselectedProviders.includes(provider.id))
    .map((provider) => provider.id);
