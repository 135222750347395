import { Button, styled } from "@material-ui/core";
import React from "react";

const CloseButton = styled(Button)(({ theme }) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    padding: "0.5rem 0",
    maxWidth: "300px",
    margin: "2rem 0",
    "&:hover": {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: "#3344FE",
        color: theme.palette.primary.light,
    },
    "& .MuiButton-label": {
        fontSize: "1.1146rem",
        fontWeight: "bold",
        textTransform: "none"
    }
}));

type CloseButtonProps = {
    onClose: () => void;
    id: string;
}

export const Close = ({ onClose, id }: CloseButtonProps) => (
    <CloseButton
        id={id}
        color="primary"
        variant="outlined"
        fullWidth
        onClick={onClose}
    >
        Close
    </CloseButton>
);
