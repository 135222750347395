import React from 'react';
import { Dialog, Popover, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { bindPopover } from 'material-ui-popup-state/hooks';

function withModal(popupState: any) {
    return (props: any) => {
        const matchesSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

        if (matchesSmScreen) {
            return <Dialog open={popupState.isOpen} {...props} />;
        }

        return (
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                {...props}
            />
        );
    };
}

export default withModal;
