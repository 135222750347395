import React from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

import { PropertyItemFragment } from 'src/generated/graphql';
import { getFeatureImage } from 'src/libs/utils';
import { Location } from 'src/components/Location';
import Text from 'src/shared/components/Text/Text';

const PropertyImageContainer = styled(Grid)(({ theme }) => ({
    width: '100px',
    display: 'inline-block',
    boxSizing: 'content-box',
    cursor: 'pointer',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
        order: 1,
    },
}));

const Content = styled(Grid)(({ theme }) => ({
    flex: 1,
    display: 'inline-block',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
        order: 3,
    },
}));

const PropertyImage = styled('img')({
    height: '70px',
    width: '100%',
    maxWidth: '100px',
});

type PropertyContainerProps = {
    property: PropertyItemFragment;
    onUnshortlistProperty: (propertyId: string) => () => void;
}

export const PropertyContainer = ({ property, onUnshortlistProperty }: PropertyContainerProps) => (
    <Box width="100%" paddingBottom="0.25rem" borderBottom="2px solid black" mb="1rem">
        <Grid container wrap="nowrap" alignItems="center" spacing={2}>
            <PropertyImageContainer item>
                <PropertyImage
                    src={getFeatureImage(property.featureImage, property.image)}
                    alt={property.image?.[0]?.title || ''}
                />
            </PropertyImageContainer>
            <Content item>
                <Grid container direction="column">
                    <Grid item>
                        <Text size="1.25rem" lineHeight={1} mb="0.25rem" bold>{property.name}</Text>
                    </Grid>
                    <Grid item>
                        <Location location={property.region?.name || ''} />
                    </Grid>
                </Grid>
            </Content>
            <Grid item>
                <IconButton size="small" color="primary" onClick={onUnshortlistProperty(property.id)}>
                    <Delete fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
    </Box>
);

export default PropertyContainer;
