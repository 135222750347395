import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
  Tenant: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



export type AddNoteInput = {
  modelName?: Maybe<NoteModel>;
  modelId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<NoteType>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
};

export type AddProviderRegionInput = {
  providerId: Scalars['String'];
  regionId: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
};

export type Amenity = {
  __typename?: 'Amenity';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  deal?: Maybe<Deal>;
  property?: Maybe<Property>;
  office?: Maybe<Office>;
};

export type AmenityInput = {
  name?: Maybe<Scalars['String']>;
  items?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
};

export type AmneityListing = {
  __typename?: 'AmneityListing';
  data: Array<Amenity>;
  previous?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type AuthInfo = {
  __typename?: 'AuthInfo';
  token?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  broker?: Maybe<Broker>;
};

export enum Availability {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN'
}

export type AvailibilityInput = {
  id?: Maybe<Scalars['String']>;
  available?: Maybe<Availability>;
};

export type Bounds = {
  neLat?: Maybe<Scalars['Float']>;
  neLng?: Maybe<Scalars['Float']>;
  swLat?: Maybe<Scalars['Float']>;
  swLng?: Maybe<Scalars['Float']>;
};

export type Broker = {
  __typename?: 'Broker';
  id: Scalars['String'];
  tenant?: Maybe<Scalars['Tenant']>;
  tenantId?: Maybe<Scalars['String']>;
  externalRef?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  bookingLink?: Maybe<Scalars['String']>;
  tokenCount?: Maybe<Scalars['Int']>;
  brokerImage?: Maybe<Media>;
  isActive: Scalars['Boolean'];
  role: Roles;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
};

export type BrokerToAddInput = {
  secondaryBroker: SecondaryBroker;
  externalRef: Scalars['String'];
};

export type BrokerToCreateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Roles;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type BrokerToUpdateInput = {
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type BudgetRange = {
  __typename?: 'BudgetRange';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type BudgetRangeWithProperties = {
  __typename?: 'BudgetRangeWithProperties';
  range: BudgetRange;
  propertiesCount: Scalars['Int'];
};

export enum BudgetSelection {
  Max = 'MAX',
  Range = 'RANGE',
  Na = 'NA'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Client = {
  __typename?: 'Client';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  externalRef?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  address?: Maybe<Array<Maybe<Address>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  deal: Array<Deal>;
};

export type ClientDeal = {
  __typename?: 'ClientDeal';
  deal: Deal;
  properties: Array<Property>;
  broker: Broker;
  secondaryBroker?: Maybe<Broker>;
};

export enum ClientInfo {
  Client = 'CLIENT',
  Contact = 'CONTACT',
  All = 'ALL',
  None = 'NONE'
}

export type ClientListing = {
  __typename?: 'ClientListing';
  data?: Maybe<Array<Maybe<Client>>>;
  previous?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum ClientOrderBy {
  Name = 'name',
  Website = 'website'
}

export type ClientProperty = {
  __typename?: 'ClientProperty';
  deal: Deal;
  property: Property;
  broker: Broker;
};

export type ClientsInput = {
  name?: Maybe<Scalars['String']>;
  items?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isSeed?: Maybe<Scalars['Boolean']>;
  seedContact?: Maybe<Contact>;
  seedContactId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  externalRef?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  contact?: Maybe<Array<Maybe<Contact>>>;
  provider?: Maybe<Array<Maybe<ContactToProvider>>>;
  property?: Maybe<Array<Maybe<Property>>>;
  deal?: Maybe<Array<Maybe<Deal>>>;
  providerRegion?: Maybe<Array<Maybe<ProviderRegion>>>;
};

export type ContactToProvider = {
  __typename?: 'ContactToProvider';
  contact: Contact;
  contactId: Scalars['String'];
  provider: Provider;
  providerId: Scalars['String'];
  contactType: ProviderContactType;
};

export type CreateAddress = {
  name?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CreateNotificationInput = {
  recipientId: Scalars['String'];
  subject: Scalars['String'];
  message: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  region?: Maybe<Array<Maybe<Region>>>;
  providerRegion?: Maybe<Array<Maybe<ProviderRegion>>>;
};


export type Deal = {
  __typename?: 'Deal';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  client: Client;
  leadBroker: Broker;
  secondaryBroker?: Maybe<Broker>;
  name: Scalars['String'];
  status: Scalars['String'];
  budget?: Maybe<Scalars['Float']>;
  desks?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  sizeUnit: SizeUnit;
  location?: Maybe<Scalars['String']>;
  locationNames: Array<Scalars['String']>;
  providerRegion?: Maybe<Scalars['String']>;
  contact: Contact;
  amenity: Array<Amenity>;
  note: Array<Note>;
  externalRef?: Maybe<Scalars['String']>;
  showProviderFilter?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  providerRegistration: Array<ProviderRegistration>;
  schedule: Schedule;
};

export type DealListing = {
  __typename?: 'DealListing';
  data: Array<Deal>;
  previous: Scalars['String'];
  next: Scalars['String'];
  total: Scalars['Int'];
};

export type DealOutput = {
  __typename?: 'DealOutput';
  deal: Deal;
  orderedProperties: Array<ScheduleProperty>;
};

export type DealsInput = {
  name?: Maybe<Scalars['String']>;
  items?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  brokerId?: Maybe<Scalars['String']>;
};

export type FavouriteUpdate = {
  __typename?: 'FavouriteUpdate';
  id: Scalars['String'];
  status: ScheduleOfficeStatus;
  favouriteCount: Scalars['Int'];
};

export type FileTitles = {
  filename?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FindOfficeFiltersInput = {
  desks?: Maybe<Range>;
  size?: Maybe<Range>;
  sizeUnit?: Maybe<SizeUnit>;
  singleFloor?: Maybe<Scalars['Boolean']>;
  budget?: Maybe<Range>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeType?: Maybe<Array<Maybe<Scalars['String']>>>;
  fittedType?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenity?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumTerm?: Maybe<Range>;
  minimumTermUnit?: Maybe<TermUnit>;
  radius?: Maybe<Scalars['Float']>;
  radiusFrom?: Maybe<RadiusPoint>;
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchString?: Maybe<Scalars['String']>;
  showUnavailable?: Maybe<Scalars['Boolean']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
};

export type FindOfficePropertyFiltersInput = {
  desks?: Maybe<Range>;
  size?: Maybe<Range>;
  sizeUnit?: Maybe<SizeUnit>;
  singleFloor?: Maybe<Scalars['Boolean']>;
  budget?: Maybe<Range>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeType?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenity?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumTerm?: Maybe<Range>;
  minimumTermUnit?: Maybe<TermUnit>;
  radius?: Maybe<Scalars['Float']>;
  radiusFrom?: Maybe<RadiusPoint>;
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchString?: Maybe<Scalars['String']>;
  showUnavailable?: Maybe<Scalars['Boolean']>;
  propertyId?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
};

export enum Fitted {
  Fitted = 'FITTED',
  Nonfitted = 'NONFITTED',
  Unknown = 'UNKNOWN'
}

export type HeyLead = {
  __typename?: 'HeyLead';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fullName: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyEmail: Scalars['String'];
  companyPhone?: Maybe<Scalars['String']>;
  desksFrom?: Maybe<Scalars['Int']>;
  desksTo?: Maybe<Scalars['Int']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  budgetFrom?: Maybe<Scalars['Float']>;
  budgetTo?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  selectedOffices?: Maybe<Scalars['Json']>;
  sessionId?: Maybe<Scalars['String']>;
};

export type HeyLeadCreateInput = {
  fullName: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyEmail: Scalars['String'];
  companyPhone?: Maybe<Scalars['String']>;
  desksFrom?: Maybe<Scalars['Int']>;
  desksTo?: Maybe<Scalars['Int']>;
  budgetOption?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  budgetFrom?: Maybe<Scalars['Float']>;
  budgetTo?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  selectedOffices?: Maybe<Scalars['Json']>;
  sessionId?: Maybe<Scalars['String']>;
};

export type HeyListing = {
  __typename?: 'HeyListing';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fullName: Scalars['String'];
  companyEmail: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};

export type HeyListingCreateInput = {
  fullName: Scalars['String'];
  companyEmail: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};

export type HeySearch = {
  __typename?: 'HeySearch';
  id: Scalars['String'];
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  sizeFrom?: Maybe<Scalars['Int']>;
  sizeTo?: Maybe<Scalars['Int']>;
  sizeOption?: Maybe<Scalars['String']>;
  budgetFrom?: Maybe<Scalars['Int']>;
  budgetTo?: Maybe<Scalars['Int']>;
  budgetOption?: Maybe<Scalars['String']>;
  amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  showUnavailable?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type Landmark = {
  __typename?: 'Landmark';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  distanceText?: Maybe<Scalars['String']>;
  durationText?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<Media>>>;
  isSeed?: Maybe<Scalars['Boolean']>;
  seedLandmark?: Maybe<Landmark>;
  seedLandmarkId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  createdById?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  updatedById?: Maybe<Scalars['String']>;
  landmark?: Maybe<Array<Maybe<Landmark>>>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
};

export type LandmarkInput = {
  placeId: Scalars['String'];
  name: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  distance: Scalars['Int'];
  duration: Scalars['Int'];
  distanceText: Scalars['String'];
  durationText: Scalars['String'];
  type: Scalars['String'];
};

export enum LeadTimeUnit {
  Week = 'WEEK',
  Month = 'MONTH'
}

export type Media = {
  __typename?: 'Media';
  id: Scalars['String'];
  tenant?: Maybe<Scalars['Tenant']>;
  tenantId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  isSeed?: Maybe<Scalars['Boolean']>;
  seedMedia?: Maybe<Media>;
  seedMediaId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  position?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  sendMagicLink: Scalars['String'];
  invalidateTokens?: Maybe<Scalars['Boolean']>;
  logout?: Maybe<Scalars['Boolean']>;
  createProperty?: Maybe<Property>;
  updateProperty?: Maybe<Property>;
  updatePropertyMedia?: Maybe<Property>;
  deleteProperty?: Maybe<Property>;
  createOffice?: Maybe<Office>;
  updateOffice?: Maybe<Office>;
  setAvailability?: Maybe<Office>;
  setNugget?: Maybe<Office>;
  outOfDateNotification?: Maybe<Array<Maybe<Office>>>;
  outOfDateSetUnavailable?: Maybe<Array<Maybe<Office>>>;
  addUser?: Maybe<User>;
  createBroker?: Maybe<Broker>;
  updateBroker?: Maybe<Broker>;
  addSecondaryBroker?: Maybe<Deal>;
  toggleShowProviderFilter?: Maybe<Deal>;
  createProvider?: Maybe<Provider>;
  updateProvider?: Maybe<Provider>;
  removeProviderRegion?: Maybe<Provider>;
  addProviderRegion?: Maybe<Provider>;
  createTour: Media;
  uploadMedia?: Maybe<Array<Maybe<Media>>>;
  updateMediaTitle?: Maybe<Media>;
  updateMediaPosition?: Maybe<Array<Maybe<Media>>>;
  createAmenity?: Maybe<Amenity>;
  addNote?: Maybe<Note>;
  retrieveNotes: Array<Note>;
  createProviderContact?: Maybe<ContactToProvider>;
  updateProviderContact?: Maybe<ContactToProvider>;
  removeProviderContact?: Maybe<ContactToProvider>;
  addProviderContact?: Maybe<ContactToProvider>;
  createProviderRegistration?: Maybe<Array<Maybe<ProviderRegistration>>>;
  createScheduleOffices?: Maybe<Array<Maybe<ScheduleOffice>>>;
  setScheduleOfficeStatus?: Maybe<ScheduleOffice>;
  updateScheduleOffice?: Maybe<ScheduleOffice>;
  removeScheduleOffices?: Maybe<ScheduleOfficesRemoved>;
  syncScheduleOffice?: Maybe<ScheduleOffice>;
  favouriteScheduleOffice?: Maybe<FavouriteUpdate>;
  favouriteScheduleOffices?: Maybe<Array<Maybe<ScheduleOffice>>>;
  notifyBroker?: Maybe<Scalars['Boolean']>;
  updateProviderRegistration?: Maybe<ProviderRegistration>;
  createNotification?: Maybe<Notification>;
  updateNotification: Array<Notification>;
  updatePropertyScheduleOrder?: Maybe<Array<Maybe<ScheduleProperty>>>;
  createHeyLead?: Maybe<HeyLead>;
  createHeyListing?: Maybe<HeyListing>;
  createRegion?: Maybe<Region>;
  updateRegion?: Maybe<Region>;
  deleteRegion?: Maybe<Region>;
};


export type MutationSendMagicLinkArgs = {
  email: Scalars['String'];
  tenantName: Scalars['String'];
};


export type MutationCreatePropertyArgs = {
  input?: Maybe<PropertyToCreateInput>;
};


export type MutationUpdatePropertyArgs = {
  input?: Maybe<PropertyToUpdateInput>;
};


export type MutationUpdatePropertyMediaArgs = {
  input?: Maybe<PropertyMediaToUpdateInput>;
};


export type MutationDeletePropertyArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationCreateOfficeArgs = {
  input?: Maybe<OfficeCreateInput>;
};


export type MutationUpdateOfficeArgs = {
  input?: Maybe<OfficeUpdateInput>;
};


export type MutationSetAvailabilityArgs = {
  input?: Maybe<AvailibilityInput>;
};


export type MutationSetNuggetArgs = {
  input?: Maybe<NuggetInput>;
};


export type MutationAddUserArgs = {
  user?: Maybe<UserInput>;
};


export type MutationCreateBrokerArgs = {
  input?: Maybe<BrokerToCreateInput>;
};


export type MutationUpdateBrokerArgs = {
  input?: Maybe<BrokerToUpdateInput>;
};


export type MutationAddSecondaryBrokerArgs = {
  input?: Maybe<BrokerToAddInput>;
};


export type MutationToggleShowProviderFilterArgs = {
  input?: Maybe<ToggleShowProviderFilterInput>;
};


export type MutationCreateProviderArgs = {
  input?: Maybe<ProviderCreateInput>;
};


export type MutationUpdateProviderArgs = {
  input?: Maybe<ProviderUpdateInput>;
};


export type MutationRemoveProviderRegionArgs = {
  input?: Maybe<RemoveProviderRegionInput>;
};


export type MutationAddProviderRegionArgs = {
  input?: Maybe<AddProviderRegionInput>;
};


export type MutationCreateTourArgs = {
  location?: Maybe<Scalars['String']>;
};


export type MutationUploadMediaArgs = {
  input?: Maybe<UploadMediaInput>;
};


export type MutationUpdateMediaTitleArgs = {
  input?: Maybe<TitleUpdate>;
};


export type MutationUpdateMediaPositionArgs = {
  input?: Maybe<UpdatePosition>;
};


export type MutationCreateAmenityArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationAddNoteArgs = {
  input?: Maybe<AddNoteInput>;
};


export type MutationRetrieveNotesArgs = {
  dealId?: Maybe<Scalars['String']>;
};


export type MutationCreateProviderContactArgs = {
  input?: Maybe<ProviderContactCreateInput>;
};


export type MutationUpdateProviderContactArgs = {
  input?: Maybe<ProviderContactUpdateInput>;
};


export type MutationRemoveProviderContactArgs = {
  input?: Maybe<ProviderContactUpdateInput>;
};


export type MutationAddProviderContactArgs = {
  input?: Maybe<ProviderContactUpdateInput>;
};


export type MutationCreateProviderRegistrationArgs = {
  input?: Maybe<ProviderRegistrationInput>;
};


export type MutationCreateScheduleOfficesArgs = {
  input?: Maybe<Array<Maybe<ScheduleOfficeCreateInput>>>;
};


export type MutationSetScheduleOfficeStatusArgs = {
  input?: Maybe<SetScheduleOfficeStatusInput>;
};


export type MutationUpdateScheduleOfficeArgs = {
  input?: Maybe<ScheduleOfficeUpdateInput>;
};


export type MutationRemoveScheduleOfficesArgs = {
  input?: Maybe<ScheduleOfficeRemoveInput>;
};


export type MutationSyncScheduleOfficeArgs = {
  input?: Maybe<SyncOfficeInput>;
};


export type MutationFavouriteScheduleOfficeArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationFavouriteScheduleOfficesArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationNotifyBrokerArgs = {
  dealId?: Maybe<Scalars['String']>;
};


export type MutationUpdateProviderRegistrationArgs = {
  input?: Maybe<ProviderRegistrationUpdate>;
};


export type MutationCreateNotificationArgs = {
  input?: Maybe<CreateNotificationInput>;
};


export type MutationUpdateNotificationArgs = {
  input?: Maybe<UpdateNotificationInput>;
};


export type MutationUpdatePropertyScheduleOrderArgs = {
  input?: Maybe<UpdatePropertyScheduleOrderInput>;
};


export type MutationCreateHeyLeadArgs = {
  input: HeyLeadCreateInput;
};


export type MutationCreateHeyListingArgs = {
  input: HeyListingCreateInput;
};


export type MutationCreateRegionArgs = {
  input?: Maybe<RegionCreateInput>;
};


export type MutationUpdateRegionArgs = {
  input?: Maybe<RegionUpdateInput>;
};


export type MutationDeleteRegionArgs = {
  id?: Maybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  note: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<NoteType>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  externalRef?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Broker;
  updatedAt: Scalars['DateTime'];
  updatedBy: Broker;
  deal?: Maybe<Deal>;
  dealId?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
  office?: Maybe<Office>;
  officeId?: Maybe<Scalars['String']>;
  scheduleOffice?: Maybe<ScheduleOffice>;
  scheduleOfficeId?: Maybe<Scalars['String']>;
  providerRegistration?: Maybe<ProviderRegistration>;
  providerRegistrationId?: Maybe<Scalars['String']>;
};

export enum NoteModel {
  Property = 'PROPERTY',
  Office = 'OFFICE',
  Deal = 'DEAL',
  ScheduleOffice = 'SCHEDULE_OFFICE'
}

export enum NoteType {
  Note = 'NOTE',
  Email = 'EMAIL',
  Task = 'TASK',
  Meeting = 'MEETING',
  Call = 'CALL'
}

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['Tenant']>;
  tenantId?: Maybe<Scalars['String']>;
  recipient?: Maybe<Broker>;
  recipientId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type NuggetInput = {
  id?: Maybe<Scalars['String']>;
  isNugget?: Maybe<Scalars['Boolean']>;
};

export type Office = {
  __typename?: 'Office';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  property: Property;
  propertyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  officeType: OfficeType;
  floor?: Maybe<Scalars['Int']>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit: SizeUnit;
  price?: Maybe<Scalars['Float']>;
  groundRent?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  estateCharge?: Maybe<Scalars['Float']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit?: Maybe<LeadTimeUnit>;
  minimumTerm?: Maybe<Scalars['Int']>;
  minimumTermUnit: TermUnit;
  available: Availability;
  note?: Maybe<Array<Maybe<Note>>>;
  amenity: Array<Amenity>;
  featureImage?: Maybe<Media>;
  floorplan: Array<Media>;
  image: Array<Media>;
  tour: Array<Media>;
  nuggetedBy?: Maybe<Broker>;
  nuggetedById?: Maybe<Scalars['String']>;
  nuggetedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  fitted: Fitted;
  show?: Maybe<Scalars['Boolean']>;
};

export type OfficeCreateInput = {
  propertyId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  officeType: OfficeType;
  floor?: Maybe<Scalars['Int']>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit?: Maybe<SizeUnit>;
  price?: Maybe<Scalars['Float']>;
  groundRent?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  estateCharge?: Maybe<Scalars['Float']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit?: Maybe<LeadTimeUnit>;
  minimumTerm?: Maybe<Scalars['Int']>;
  minimumTermUnit: TermUnit;
  note?: Maybe<Scalars['String']>;
  available?: Maybe<Availability>;
  featureImageId?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorplanIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  fitted: Fitted;
};

export type OfficeFiltersInput = {
  desks?: Maybe<Range>;
  size?: Maybe<Range>;
  sizeUnit?: Maybe<SizeUnit>;
  budget?: Maybe<Range>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeType?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenity?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumTerm?: Maybe<Range>;
  minimumTermUnit?: Maybe<TermUnit>;
  radius?: Maybe<Scalars['Float']>;
  radiusFrom?: Maybe<RadiusPoint>;
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchString?: Maybe<Scalars['String']>;
  showUnavailable?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['String']>;
  bounds?: Maybe<Bounds>;
  availableFrom?: Maybe<Scalars['DateTime']>;
};

export type OfficeInput = {
  name?: Maybe<Scalars['String']>;
  nuggets?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  desks?: Maybe<Range>;
  size?: Maybe<Range>;
  sizeUnit?: Maybe<SizeUnit>;
  budget?: Maybe<Range>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeType?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenity?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumTerm?: Maybe<Range>;
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  showUnavailable?: Maybe<Scalars['Boolean']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  singleFloor?: Maybe<Scalars['Boolean']>;
};

export type OfficeListing = {
  __typename?: 'OfficeListing';
  data: Array<Office>;
  previous?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type OfficeSearchBudgetInput = {
  filter?: Maybe<OfficeFiltersInput>;
  unselectedProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
  budgetOption?: Maybe<Scalars['String']>;
};

export type OfficeSearchData = {
  __typename?: 'OfficeSearchData';
  regions: Array<PartialRegion>;
  amenities: Array<PartialAmenity>;
  providers: Array<PartialProvider>;
};

export type OfficeSearchDataBudget = {
  __typename?: 'OfficeSearchDataBudget';
  avgPrice?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  data: Array<BudgetRangeWithProperties>;
};

export type OfficeSearchDataProviders = {
  __typename?: 'OfficeSearchDataProviders';
  providers: Array<PartialProvider>;
};

export type OfficeSearchProperties = {
  __typename?: 'OfficeSearchProperties';
  properties: Array<Property>;
};

export type OfficeSearchProperty = {
  __typename?: 'OfficeSearchProperty';
  property?: Maybe<Property>;
};

export enum OfficeType {
  Private = 'PRIVATE',
  Managed = 'MANAGED',
  Hotdesk = 'HOTDESK',
  Lease = 'LEASE',
  Sublease = 'SUBLEASE'
}

export type OfficeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  officeType?: Maybe<OfficeType>;
  floor?: Maybe<Scalars['Int']>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit?: Maybe<SizeUnit>;
  price?: Maybe<Scalars['Float']>;
  groundRent?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  estateCharge?: Maybe<Scalars['Float']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit?: Maybe<LeadTimeUnit>;
  minimumTerm?: Maybe<Scalars['Int']>;
  minimumTermUnit?: Maybe<TermUnit>;
  available?: Maybe<Availability>;
  featureImageId?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorplanIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNugget?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  fitted?: Maybe<Fitted>;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PartialAmenity = {
  __typename?: 'PartialAmenity';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PartialContact = {
  __typename?: 'PartialContact';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type PartialProvider = {
  __typename?: 'PartialProvider';
  id: Scalars['String'];
  name: Scalars['String'];
  officeCount?: Maybe<Scalars['Int']>;
  propertiesCount?: Maybe<Scalars['Int']>;
  providerRegion?: Maybe<Array<ProviderRegion>>;
};

export type PartialRegion = {
  __typename?: 'PartialRegion';
  id: Scalars['String'];
  name: Scalars['String'];
  parentRegionId?: Maybe<Scalars['String']>;
};

export type Place = {
  __typename?: 'Place';
  placeId: Scalars['String'];
  name: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  distance: Scalars['Int'];
  duration: Scalars['Int'];
  distanceText: Scalars['String'];
  durationText: Scalars['String'];
};

export type PlaceRating = {
  __typename?: 'PlaceRating';
  rating?: Maybe<Scalars['Float']>;
};

export type PlaceRatingInput = {
  placeId: Scalars['String'];
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  provider: Provider;
  providerId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  providerRegion: ProviderRegion;
  region: Region;
  propertyType: PropertyType;
  serviceType: ServiceType;
  address: Address;
  addressId: Scalars['String'];
  contact?: Maybe<Array<Contact>>;
  amenity: Array<Amenity>;
  floorplan: Array<Media>;
  featureImage?: Maybe<Media>;
  image: Array<Media>;
  tour: Array<Media>;
  seedProperty?: Maybe<Property>;
  seedPropertyId?: Maybe<Scalars['String']>;
  note: Array<Note>;
  isSeed?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  landmark: Array<Landmark>;
  property?: Maybe<Array<Maybe<Property>>>;
  office: Array<Office>;
  scheduleOffice: Array<ScheduleOffice>;
  maxDesks?: Maybe<Scalars['Int']>;
  maxSize?: Maybe<Scalars['Int']>;
  minTotalCost?: Maybe<Scalars['Float']>;
  minTermDays?: Maybe<Scalars['Int']>;
  officeCount?: Maybe<Scalars['Int']>;
  wholeBuildingOnly?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  filteredOfficeCount?: Maybe<Scalars['Int']>;
  availableOfficeCount?: Maybe<Scalars['Int']>;
  filteredMinTotalCost?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
};

export type PropertyCreateData = {
  __typename?: 'PropertyCreateData';
  providers: Array<PropertyCreateDataProvider>;
  contacts: Array<PropertyCreateDataContact>;
};

export type PropertyCreateDataContact = {
  __typename?: 'PropertyCreateDataContact';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type PropertyCreateDataProvider = {
  __typename?: 'PropertyCreateDataProvider';
  id: Scalars['String'];
  name: Scalars['String'];
  providerRegion: Array<ProviderRegion>;
};

export type PropertyInput = {
  name?: Maybe<Scalars['String']>;
  items?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
};

export type PropertyListing = {
  __typename?: 'PropertyListing';
  data?: Maybe<Array<Maybe<Property>>>;
  previous?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type PropertyMediaToUpdateInput = {
  id?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorplanIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyToCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  propertyType: PropertyType;
  serviceType: ServiceType;
  providerId: Scalars['String'];
  providerRegionId: Scalars['String'];
  contactId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  address: CreateAddress;
  featureImageId?: Maybe<Scalars['String']>;
  landmarks?: Maybe<Array<Maybe<LandmarkInput>>>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorplanIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  wholeBuildingOnly?: Maybe<Scalars['Boolean']>;
};

export type PropertyToUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  propertyType?: Maybe<PropertyType>;
  serviceType?: Maybe<ServiceType>;
  providerId?: Maybe<Scalars['String']>;
  providerRegionId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateAddress>;
  landmarks?: Maybe<Array<Maybe<LandmarkInput>>>;
  featureImageId?: Maybe<Scalars['String']>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  wholeBuildingOnly?: Maybe<Scalars['Boolean']>;
};

export enum PropertyType {
  Lease = 'LEASE',
  Flexible = 'FLEXIBLE',
  Purchase = 'PURCHASE',
  Sublease = 'SUBLEASE'
}

export type Provider = {
  __typename?: 'Provider';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  name: Scalars['String'];
  nameSearch?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headquarters?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['String']>;
  alwaysAvailable: Scalars['Boolean'];
  contactCount?: Maybe<Scalars['Int']>;
  propertyCount?: Maybe<Scalars['Int']>;
  officeCount?: Maybe<Scalars['Int']>;
  officeAvailableCount?: Maybe<Scalars['Int']>;
  logo?: Maybe<Media>;
  logoId?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  providerContact: Array<ContactToProvider>;
  isSeed?: Maybe<Scalars['Boolean']>;
  seedProvider?: Maybe<Provider>;
  seedProviderId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  provider?: Maybe<Array<Maybe<Provider>>>;
  property?: Maybe<Array<Property>>;
  providerRegion?: Maybe<Array<ProviderRegion>>;
  providerRegistration?: Maybe<Array<ProviderRegistration>>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ProviderContactCreateInput = {
  providerId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  contactType: ProviderContactType;
};

export enum ProviderContactType {
  Register = 'REGISTER',
  Other = 'OTHER'
}

export type ProviderContactUpdateInput = {
  providerId: Scalars['String'];
  contactId: Scalars['String'];
  contactType: ProviderContactType;
};

export type ProviderCreateDataType = {
  __typename?: 'ProviderCreateDataType';
  contacts: Array<PartialContact>;
  regions: Array<RegionItem>;
};

export type ProviderCreateInput = {
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  contactIds: Array<Maybe<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  alwaysAvailable?: Maybe<Scalars['Boolean']>;
  logoId?: Maybe<Scalars['String']>;
  headquarters?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  address: CreateAddress;
  isActive?: Maybe<Scalars['Boolean']>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ProviderInput = {
  name: Scalars['String'];
  items?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
};

export type ProviderListing = {
  __typename?: 'ProviderListing';
  data: Array<Provider>;
  previous: Scalars['String'];
  next: Scalars['String'];
  total: Scalars['Int'];
};

export type ProviderRegion = {
  __typename?: 'ProviderRegion';
  id: Scalars['String'];
  tenant?: Maybe<Scalars['Tenant']>;
  tenantId?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['String']>;
  region: Region;
  regionId: Scalars['String'];
  contact?: Maybe<Array<Maybe<Contact>>>;
  property?: Maybe<Array<Maybe<Property>>>;
  isSeed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  createdById?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  updatedById?: Maybe<Scalars['String']>;
};

export type ProviderRegisterData = {
  __typename?: 'ProviderRegisterData';
  providers: Array<PartialProvider>;
  amenities: Array<PartialAmenity>;
};

export type ProviderRegistration = {
  __typename?: 'ProviderRegistration';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  deal: Deal;
  dealId: Scalars['String'];
  provider: Provider;
  providerId: Scalars['String'];
  clientInfo: ClientInfo;
  budgetFrom?: Maybe<Scalars['Int']>;
  budgetTo?: Maybe<Scalars['Int']>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit: SizeUnit;
  location: Scalars['String'];
  targetDate?: Maybe<Scalars['DateTime']>;
  flexibleDate?: Maybe<Scalars['Boolean']>;
  amenity: Array<Amenity>;
  note: Array<Note>;
  status: RegistrationStatus;
  sentAt?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  createdBy: Broker;
  createdById: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Broker;
  updatedById: Scalars['String'];
};

export type ProviderRegistrationInput = {
  dealId: Scalars['String'];
  providerIds: Array<Scalars['String']>;
  clientInfo: ClientInfo;
  amenityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  budgetSelection: BudgetSelection;
  budgetFrom?: Maybe<Scalars['Int']>;
  budgetTo?: Maybe<Scalars['Int']>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit?: Maybe<SizeUnit>;
  location?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['DateTime']>;
  flexibleDate: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
};

export type ProviderRegistrationUpdate = {
  id: Scalars['String'];
  status: RegistrationStatus;
};

export type ProviderUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alwaysAvailable?: Maybe<Scalars['Boolean']>;
  contactIds: Array<Scalars['String']>;
  logoId?: Maybe<Scalars['String']>;
  headquarters?: Maybe<Scalars['String']>;
  address?: Maybe<CreateAddress>;
  isActive?: Maybe<Scalars['Boolean']>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type PublicFilterPropertiesInput = {
  desks?: Maybe<Range>;
  size?: Maybe<Range>;
  sizeUnit?: Maybe<SizeUnit>;
  budget?: Maybe<Range>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeType?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenity?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumTerm?: Maybe<Range>;
  minimumTermUnit?: Maybe<TermUnit>;
  radius?: Maybe<Scalars['Float']>;
  radiusFrom?: Maybe<RadiusPoint>;
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchString?: Maybe<Scalars['String']>;
  showUnavailable?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['String']>;
  bounds?: Maybe<Bounds>;
  availableFrom?: Maybe<Scalars['DateTime']>;
};

export type PublicPropertiesInput = {
  ids: Array<Scalars['String']>;
  filter?: Maybe<PublicFilterPropertiesInput>;
};

export type PublicPropertyInput = {
  id?: Maybe<Scalars['String']>;
  filter?: Maybe<PublicFilterPropertiesInput>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<Broker>;
  client?: Maybe<Client>;
  clients?: Maybe<ClientListing>;
  properties?: Maybe<PropertyListing>;
  property: Property;
  propertyCreateData: PropertyCreateData;
  office?: Maybe<Office>;
  offices: Array<Office>;
  officeListing?: Maybe<OfficeListing>;
  officeSearchProperties: OfficeSearchProperties;
  officeSearchProperty: OfficeSearchProperty;
  officeSearchData?: Maybe<OfficeSearchData>;
  officeSearchDataProviders?: Maybe<OfficeSearchDataProviders>;
  officeSearchDataBudget?: Maybe<OfficeSearchDataBudget>;
  getUser?: Maybe<Array<Maybe<User>>>;
  brokers: Array<Broker>;
  getBroker?: Maybe<Broker>;
  deals: DealListing;
  dealList: Array<Deal>;
  deal?: Maybe<DealOutput>;
  providers?: Maybe<ProviderListing>;
  provider?: Maybe<Provider>;
  providerCreateData?: Maybe<ProviderCreateDataType>;
  propertyMedia?: Maybe<Array<Maybe<Media>>>;
  amenities?: Maybe<AmneityListing>;
  note?: Maybe<Note>;
  contacts: Array<Contact>;
  providerRegion?: Maybe<ProviderRegion>;
  providerRegisterData?: Maybe<ProviderRegisterData>;
  schedule?: Maybe<Schedule>;
  scheduleOffice?: Maybe<ScheduleOffice>;
  clientDeal?: Maybe<ClientDeal>;
  /** These are used by HeyOffices for displaying the various sets of data */
  publicFilterProperties: Array<Property>;
  publicProperties: Array<Property>;
  publicProperty: Property;
  places: Array<Place>;
  placeRating?: Maybe<PlaceRating>;
  notifications: Array<Notification>;
  currencies: Array<Currency>;
  regions: Array<Region>;
};


export type QueryClientArgs = {
  id: Scalars['String'];
};


export type QueryClientsArgs = {
  input?: Maybe<ClientsInput>;
};


export type QueryPropertiesArgs = {
  input?: Maybe<PropertyInput>;
};


export type QueryPropertyArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryOfficeArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryOfficesArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryOfficeListingArgs = {
  filter?: Maybe<OfficeInput>;
};


export type QueryOfficeSearchPropertiesArgs = {
  filter?: Maybe<FindOfficeFiltersInput>;
};


export type QueryOfficeSearchPropertyArgs = {
  filter?: Maybe<FindOfficePropertyFiltersInput>;
};


export type QueryOfficeSearchDataProvidersArgs = {
  filter?: Maybe<OfficeFiltersInput>;
};


export type QueryOfficeSearchDataBudgetArgs = {
  input?: Maybe<OfficeSearchBudgetInput>;
};


export type QueryGetUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetBrokerArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryDealsArgs = {
  input?: Maybe<DealsInput>;
};


export type QueryDealArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryProvidersArgs = {
  input?: Maybe<ProviderInput>;
};


export type QueryProviderArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryPropertyMediaArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryAmenitiesArgs = {
  input?: Maybe<AmenityInput>;
};


export type QueryNoteArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryProviderRegionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryScheduleArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryScheduleOfficeArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryClientDealArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryPublicFilterPropertiesArgs = {
  filter?: Maybe<PublicFilterPropertiesInput>;
};


export type QueryPublicPropertiesArgs = {
  publicPropertiesInput?: Maybe<PublicPropertiesInput>;
};


export type QueryPublicPropertyArgs = {
  publicPropertyInput?: Maybe<PublicPropertyInput>;
};


export type QueryPlacesArgs = {
  input?: Maybe<PlaceInput>;
};


export type QueryPlaceRatingArgs = {
  input?: Maybe<PlaceRatingInput>;
};

export type RadiusPoint = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type Range = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  name: Scalars['String'];
  nameSearch: Scalars['String'];
  currency: Currency;
  currencyId: Scalars['String'];
  providerRegion?: Maybe<Array<Maybe<ProviderRegion>>>;
  parentRegion?: Maybe<Region>;
  parentRegionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  createdById?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  updatedById?: Maybe<Scalars['String']>;
  region?: Maybe<Array<Maybe<Region>>>;
};

export type RegionCreateInput = {
  name: Scalars['String'];
  currencyId: Scalars['String'];
  parentRegionId?: Maybe<Scalars['String']>;
};

export type RegionItem = {
  __typename?: 'RegionItem';
  id: Scalars['String'];
  name: Scalars['String'];
  parentRegionId?: Maybe<Scalars['String']>;
};

export type RegionUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  currencyId: Scalars['String'];
  parentRegionId?: Maybe<Scalars['String']>;
};

export enum RegistrationStatus {
  Creating = 'CREATING',
  Sent = 'SENT',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Override = 'OVERRIDE'
}

export type RemoveProviderRegionInput = {
  providerId: Scalars['String'];
  providerRegionId: Scalars['String'];
};

export enum Roles {
  User = 'USER',
  Admin = 'ADMIN',
  Loader = 'LOADER',
  Public = 'PUBLIC'
}

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  deal: Deal;
  dealId: Scalars['String'];
  favouritesSent: Scalars['Int'];
  note?: Maybe<Array<Maybe<Note>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  createdById?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  updatedById?: Maybe<Scalars['String']>;
  scheduleOffice: Array<ScheduleOffice>;
  scheduleProperty: Array<ScheduleProperty>;
};

export type ScheduleOffice = {
  __typename?: 'ScheduleOffice';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  property: Property;
  propertyId: Scalars['String'];
  schedule: Schedule;
  scheduleId: Scalars['String'];
  office: Office;
  officeId: Scalars['String'];
  status: ScheduleOfficeStatus;
  officeType: OfficeType;
  name: Scalars['String'];
  nameSearch?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit: SizeUnit;
  minimumTerm?: Maybe<Scalars['Int']>;
  minimumTermUnit: TermUnit;
  rentFreePeriod?: Maybe<Scalars['Int']>;
  rentFreePeriodUnit: TermUnit;
  available: Availability;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit?: Maybe<LeadTimeUnit>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  groundRent?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  estateCharge?: Maybe<Scalars['Float']>;
  nuggetedBy?: Maybe<Broker>;
  nuggetedById?: Maybe<Scalars['String']>;
  nuggetedAt?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Array<Maybe<Note>>>;
  amenity: Array<Amenity>;
  featureImage?: Maybe<Media>;
  floorplan: Array<Media>;
  image: Array<Media>;
  tour: Array<Media>;
  linkedToOffice: Array<ScheduleOffice>;
  linkedByOffice: Array<ScheduleOffice>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  createdById?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
  updatedById?: Maybe<Scalars['String']>;
  fitted: Fitted;
};

export type ScheduleOfficeCreateInput = {
  scheduleId: Scalars['String'];
  officeId: Scalars['String'];
  propertyId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  officeType: OfficeType;
  floor?: Maybe<Scalars['Int']>;
  fitted?: Maybe<Fitted>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit?: Maybe<SizeUnit>;
  price?: Maybe<Scalars['Float']>;
  groundRent?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  estateCharge?: Maybe<Scalars['Float']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  minimumTerm?: Maybe<Scalars['Int']>;
  minimumTermUnit: TermUnit;
  note?: Maybe<Scalars['String']>;
  available?: Maybe<Availability>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit?: Maybe<LeadTimeUnit>;
  featureImageId?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorplanIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkToOffices?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ScheduleOfficeRemoveInput = {
  id: Array<Scalars['String']>;
};

export enum ScheduleOfficeStatus {
  New = 'NEW',
  Favourite = 'FAVOURITE',
  Viewing = 'VIEWING',
  Offer = 'OFFER',
  Rejected = 'REJECTED'
}

export type ScheduleOfficeUpdateInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<ScheduleOfficeStatus>;
  officeType?: Maybe<OfficeType>;
  fitted?: Maybe<Fitted>;
  floor?: Maybe<Scalars['Int']>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit?: Maybe<SizeUnit>;
  price?: Maybe<Scalars['Float']>;
  groundRent?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  estateCharge?: Maybe<Scalars['Float']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit?: Maybe<LeadTimeUnit>;
  minimumTerm?: Maybe<Scalars['Int']>;
  minimumTermUnit?: Maybe<TermUnit>;
  rentFreePeriod?: Maybe<Scalars['Int']>;
  rentFreePeriodUnit?: Maybe<TermUnit>;
  available?: Maybe<Availability>;
  featureImageId?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorplanIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNugget?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type ScheduleOfficesRemoved = {
  __typename?: 'ScheduleOfficesRemoved';
  count?: Maybe<Scalars['Int']>;
};

export type ScheduleProperty = {
  __typename?: 'ScheduleProperty';
  id: Scalars['String'];
  tenant: Scalars['Tenant'];
  tenantId: Scalars['String'];
  schedule: Schedule;
  scheduleId: Scalars['String'];
  property: Property;
  propertyId: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Broker>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Broker>;
};

export type SecondaryBroker = {
  brokerName: Scalars['String'];
};

export enum ServiceType {
  Propco = 'PROPCO',
  Leased = 'LEASED',
  Agreement = 'AGREEMENT'
}

export type SetScheduleOfficeStatusInput = {
  officeId: Scalars['String'];
  status?: Maybe<ScheduleOfficeStatus>;
};

export enum SizeUnit {
  SquareFeet = 'SQUARE_FEET',
  SquareMeters = 'SQUARE_METERS'
}

export type SyncOfficeInput = {
  masterOfficeId: Scalars['String'];
  scheduleOfficeId: Scalars['String'];
  officeType?: Maybe<OfficeType>;
  name?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  fitted?: Maybe<Fitted>;
  desks?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sizeUnit?: Maybe<SizeUnit>;
  minimumTerm?: Maybe<Scalars['Int']>;
  minimumTermUnit?: Maybe<TermUnit>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit?: Maybe<LeadTimeUnit>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  groundRent?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  estateCharge?: Maybe<Scalars['Float']>;
  available?: Maybe<Availability>;
  amenity?: Maybe<Array<Maybe<Scalars['String']>>>;
  featureImage?: Maybe<Scalars['String']>;
  floorplan?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Array<Maybe<Scalars['String']>>>;
  tour?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export enum TermUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR'
}

export type TitleUpdate = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ToggleShowProviderFilterInput = {
  id: Scalars['String'];
  showProviderFilter?: Maybe<Scalars['Boolean']>;
};

export type UpdateNotificationInput = {
  notificationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdatePosition = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UploadMediaInput = {
  type?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  titles?: Maybe<Array<Maybe<FileTitles>>>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
};

export type UserInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
};

export type PlaceInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  placeType?: Maybe<Scalars['String']>;
};

export type UpdatePropertyScheduleOrderInput = {
  scheduleId: Scalars['String'];
  schedulePropertyIds: Array<Scalars['String']>;
};

export type CreateHeyListingMutationVariables = Exact<{
  input: HeyListingCreateInput;
}>;


export type CreateHeyListingMutation = (
  { __typename?: 'Mutation' }
  & { createHeyListing?: Maybe<(
    { __typename?: 'HeyListing' }
    & Pick<HeyListing, 'fullName' | 'companyEmail' | 'message'>
  )> }
);

export type PlaceRatingQueryVariables = Exact<{
  input: PlaceRatingInput;
}>;


export type PlaceRatingQuery = (
  { __typename?: 'Query' }
  & { placeRating?: Maybe<(
    { __typename?: 'PlaceRating' }
    & Pick<PlaceRating, 'rating'>
  )> }
);

export type PublicFilterPropertiesQueryVariables = Exact<{
  filter?: Maybe<PublicFilterPropertiesInput>;
}>;


export type PublicFilterPropertiesQuery = (
  { __typename?: 'Query' }
  & { publicFilterProperties: Array<(
    { __typename?: 'Property' }
    & PropertyListItemFragment
  )> }
);

export type PropertyListItemFragment = (
  { __typename?: 'Property' }
  & Pick<Property, 'id' | 'minTotalCost' | 'filteredMinTotalCost' | 'officeCount' | 'filteredOfficeCount' | 'availableOfficeCount' | 'createdAt' | 'updatedAt'>
  & { address: (
    { __typename?: 'Address' }
    & AddressItemFragment
  ), provider: (
    { __typename?: 'Provider' }
    & Pick<Provider, 'id'>
  ) }
);

export type CreateHeyLeadMutationVariables = Exact<{
  input: HeyLeadCreateInput;
}>;


export type CreateHeyLeadMutation = (
  { __typename?: 'Mutation' }
  & { createHeyLead?: Maybe<(
    { __typename?: 'HeyLead' }
    & Pick<HeyLead, 'fullName' | 'companyEmail' | 'companyPhone' | 'desksFrom' | 'desksTo' | 'locations' | 'budgetFrom' | 'budgetTo' | 'selectedOffices'>
  )> }
);

export type OfficeSearchDataAmenitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type OfficeSearchDataAmenitiesQuery = (
  { __typename?: 'Query' }
  & { officeSearchData?: Maybe<(
    { __typename?: 'OfficeSearchData' }
    & { amenities: Array<(
      { __typename?: 'PartialAmenity' }
      & Pick<PartialAmenity, 'id' | 'name'>
    )> }
  )> }
);

export type OfficeSearchDataRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type OfficeSearchDataRegionsQuery = (
  { __typename?: 'Query' }
  & { officeSearchData?: Maybe<(
    { __typename?: 'OfficeSearchData' }
    & { regions: Array<(
      { __typename?: 'PartialRegion' }
      & Pick<PartialRegion, 'id' | 'name' | 'parentRegionId'>
    )> }
  )> }
);

export type OfficeSearchDataProvidersQueryVariables = Exact<{
  filter?: Maybe<OfficeFiltersInput>;
}>;


export type OfficeSearchDataProvidersQuery = (
  { __typename?: 'Query' }
  & { officeSearchDataProviders?: Maybe<(
    { __typename?: 'OfficeSearchDataProviders' }
    & { providers: Array<(
      { __typename?: 'PartialProvider' }
      & Pick<PartialProvider, 'id' | 'name' | 'propertiesCount' | 'officeCount'>
    )> }
  )> }
);

export type OfficeSearchDataBudgetQueryVariables = Exact<{
  input?: Maybe<OfficeSearchBudgetInput>;
}>;


export type OfficeSearchDataBudgetQuery = (
  { __typename?: 'Query' }
  & { officeSearchDataBudget?: Maybe<(
    { __typename?: 'OfficeSearchDataBudget' }
    & Pick<OfficeSearchDataBudget, 'avgPrice' | 'min' | 'max'>
    & { data: Array<(
      { __typename?: 'BudgetRangeWithProperties' }
      & Pick<BudgetRangeWithProperties, 'propertiesCount'>
      & { range: (
        { __typename?: 'BudgetRange' }
        & Pick<BudgetRange, 'from' | 'to'>
      ) }
    )> }
  )> }
);

export type PublicPropertiesQueryVariables = Exact<{
  publicPropertiesInput?: Maybe<PublicPropertiesInput>;
}>;


export type PublicPropertiesQuery = (
  { __typename?: 'Query' }
  & { publicProperties: Array<(
    { __typename?: 'Property' }
    & PropertiesListItemFragment
  )> }
);

export type PropertiesListItemFragment = (
  { __typename?: 'Property' }
  & Pick<Property, 'id' | 'position' | 'name' | 'isActive' | 'createdAt' | 'updatedAt' | 'officeCount' | 'filteredOfficeCount' | 'availableOfficeCount' | 'filteredMinTotalCost'>
  & { office: Array<(
    { __typename?: 'Office' }
    & Pick<Office, 'desks' | 'size' | 'totalCost' | 'minimumTerm' | 'minimumTermUnit'>
  )>, address: (
    { __typename?: 'Address' }
    & AddressItemFragment
  ), floorplan: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, featureImage?: Maybe<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, image: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, tour: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, region: (
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
  ), landmark: Array<(
    { __typename?: 'Landmark' }
    & LandmarkItemFragment
  )> }
);

export type PublicPropertyQueryVariables = Exact<{
  publicPropertyInput?: Maybe<PublicPropertyInput>;
}>;


export type PublicPropertyQuery = (
  { __typename?: 'Query' }
  & { publicProperty: (
    { __typename?: 'Property' }
    & PropertyItemFragment
  ) }
);

export type PropertyItemFragment = (
  { __typename?: 'Property' }
  & Pick<Property, 'id' | 'name' | 'position' | 'description' | 'show' | 'propertyType' | 'isActive' | 'createdAt' | 'updatedAt'>
  & { region: (
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
  ), provider: (
    { __typename?: 'Provider' }
    & ProviderItemFragment
  ), address: (
    { __typename?: 'Address' }
    & AddressItemFragment
  ), amenity: Array<(
    { __typename?: 'Amenity' }
    & AmenityItemFragment
  )>, floorplan: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, featureImage?: Maybe<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, image: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, tour: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, landmark: Array<(
    { __typename?: 'Landmark' }
    & LandmarkItemFragment
  )>, office: Array<(
    { __typename?: 'Office' }
    & OfficeItemFragment
  )> }
);

export type MediaItemFragment = (
  { __typename?: 'Media' }
  & Pick<Media, 'id' | 'title' | 'type' | 'location' | 'mimetype'>
);

export type AddressItemFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'latitude' | 'longitude'>
);

export type AmenityItemFragment = (
  { __typename?: 'Amenity' }
  & Pick<Amenity, 'id' | 'name'>
);

export type LandmarkItemFragment = (
  { __typename?: 'Landmark' }
  & Pick<Landmark, 'name' | 'type' | 'description' | 'distance' | 'duration' | 'distanceText' | 'durationText' | 'isActive'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & AddressItemFragment
  )> }
);

export type OfficeItemFragment = (
  { __typename?: 'Office' }
  & Pick<Office, 'id' | 'name' | 'show' | 'description' | 'officeType' | 'floor' | 'desks' | 'size' | 'price' | 'groundRent' | 'rates' | 'totalCost' | 'availableFrom' | 'leadTime' | 'leadTimeUnit' | 'minimumTerm' | 'minimumTermUnit' | 'available' | 'createdAt' | 'updatedAt'>
  & { amenity: Array<(
    { __typename?: 'Amenity' }
    & AmenityItemFragment
  )>, featureImage?: Maybe<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, floorplan: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, image: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, tour: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )> }
);

export type ProviderItemFragment = (
  { __typename?: 'Provider' }
  & Pick<Provider, 'id' | 'name' | 'contactNumber' | 'email'>
);

export type PublicPropertyShortenQueryVariables = Exact<{
  publicPropertyInput?: Maybe<PublicPropertyInput>;
}>;


export type PublicPropertyShortenQuery = (
  { __typename?: 'Query' }
  & { publicProperty: (
    { __typename?: 'Property' }
    & PropertyShortenItemFragment
  ) }
);

export type PropertyShortenItemFragment = (
  { __typename?: 'Property' }
  & Pick<Property, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  & { region: (
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
  ), featureImage?: Maybe<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )>, image: Array<(
    { __typename?: 'Media' }
    & MediaItemFragment
  )> }
);

export type MapRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type MapRegionsQuery = (
  { __typename?: 'Query' }
  & { regions: Array<(
    { __typename?: 'Region' }
    & RegionItemFragment
  )> }
);

export type RegionItemFragment = (
  { __typename?: 'Region' }
  & Pick<Region, 'id' | 'name' | 'parentRegionId'>
);

export const AddressItemFragmentDoc = gql`
    fragment AddressItem on Address {
  latitude
  longitude
}
    `;
export const PropertyListItemFragmentDoc = gql`
    fragment PropertyListItem on Property {
  id
  minTotalCost
  filteredMinTotalCost
  officeCount
  filteredOfficeCount
  availableOfficeCount
  address {
    ...AddressItem
  }
  provider {
    id
  }
  createdAt
  updatedAt
}
    ${AddressItemFragmentDoc}`;
export const MediaItemFragmentDoc = gql`
    fragment MediaItem on Media {
  id
  title
  type
  location
  mimetype
}
    `;
export const LandmarkItemFragmentDoc = gql`
    fragment LandmarkItem on Landmark {
  address {
    ...AddressItem
  }
  name
  type
  description
  distance
  duration
  distanceText
  durationText
  isActive
}
    ${AddressItemFragmentDoc}`;
export const PropertiesListItemFragmentDoc = gql`
    fragment PropertiesListItem on Property {
  id
  position
  name
  office {
    desks
    size
    totalCost
    minimumTerm
    minimumTermUnit
  }
  address {
    ...AddressItem
  }
  floorplan {
    ...MediaItem
  }
  featureImage {
    ...MediaItem
  }
  image {
    ...MediaItem
  }
  tour {
    ...MediaItem
  }
  region {
    id
    name
  }
  isActive
  createdAt
  updatedAt
  landmark {
    ...LandmarkItem
  }
  officeCount
  filteredOfficeCount
  availableOfficeCount
  filteredMinTotalCost
}
    ${AddressItemFragmentDoc}
${MediaItemFragmentDoc}
${LandmarkItemFragmentDoc}`;
export const ProviderItemFragmentDoc = gql`
    fragment ProviderItem on Provider {
  id
  name
  contactNumber
  email
}
    `;
export const AmenityItemFragmentDoc = gql`
    fragment AmenityItem on Amenity {
  id
  name
}
    `;
export const OfficeItemFragmentDoc = gql`
    fragment OfficeItem on Office {
  id
  name
  show
  description
  officeType
  floor
  desks
  size
  price
  groundRent
  rates
  totalCost
  availableFrom
  leadTime
  leadTimeUnit
  minimumTerm
  minimumTermUnit
  available
  amenity {
    ...AmenityItem
  }
  featureImage {
    ...MediaItem
  }
  floorplan {
    ...MediaItem
  }
  image {
    ...MediaItem
  }
  tour {
    ...MediaItem
  }
  createdAt
  updatedAt
}
    ${AmenityItemFragmentDoc}
${MediaItemFragmentDoc}`;
export const PropertyItemFragmentDoc = gql`
    fragment PropertyItem on Property {
  id
  name
  position
  description
  region {
    id
    name
  }
  provider {
    ...ProviderItem
  }
  show
  propertyType
  address {
    ...AddressItem
  }
  amenity {
    ...AmenityItem
  }
  floorplan {
    ...MediaItem
  }
  featureImage {
    ...MediaItem
  }
  image {
    ...MediaItem
  }
  tour {
    ...MediaItem
  }
  isActive
  createdAt
  updatedAt
  landmark {
    ...LandmarkItem
  }
  office {
    ...OfficeItem
  }
}
    ${ProviderItemFragmentDoc}
${AddressItemFragmentDoc}
${AmenityItemFragmentDoc}
${MediaItemFragmentDoc}
${LandmarkItemFragmentDoc}
${OfficeItemFragmentDoc}`;
export const PropertyShortenItemFragmentDoc = gql`
    fragment PropertyShortenItem on Property {
  id
  name
  region {
    id
    name
  }
  featureImage {
    ...MediaItem
  }
  image {
    ...MediaItem
  }
  createdAt
  updatedAt
}
    ${MediaItemFragmentDoc}`;
export const RegionItemFragmentDoc = gql`
    fragment RegionItem on Region {
  id
  name
  parentRegionId
}
    `;
export const CreateHeyListingDocument = gql`
    mutation createHeyListing($input: HeyListingCreateInput!) {
  createHeyListing(input: $input) {
    fullName
    companyEmail
    message
  }
}
    `;
export type CreateHeyListingMutationFn = Apollo.MutationFunction<CreateHeyListingMutation, CreateHeyListingMutationVariables>;

/**
 * __useCreateHeyListingMutation__
 *
 * To run a mutation, you first call `useCreateHeyListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHeyListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHeyListingMutation, { data, loading, error }] = useCreateHeyListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHeyListingMutation(baseOptions?: Apollo.MutationHookOptions<CreateHeyListingMutation, CreateHeyListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHeyListingMutation, CreateHeyListingMutationVariables>(CreateHeyListingDocument, options);
      }
export type CreateHeyListingMutationHookResult = ReturnType<typeof useCreateHeyListingMutation>;
export type CreateHeyListingMutationResult = Apollo.MutationResult<CreateHeyListingMutation>;
export type CreateHeyListingMutationOptions = Apollo.BaseMutationOptions<CreateHeyListingMutation, CreateHeyListingMutationVariables>;
export const PlaceRatingDocument = gql`
    query placeRating($input: PlaceRatingInput!) {
  placeRating(input: $input) {
    rating
  }
}
    `;

/**
 * __usePlaceRatingQuery__
 *
 * To run a query within a React component, call `usePlaceRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceRatingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceRatingQuery(baseOptions: Apollo.QueryHookOptions<PlaceRatingQuery, PlaceRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceRatingQuery, PlaceRatingQueryVariables>(PlaceRatingDocument, options);
      }
export function usePlaceRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceRatingQuery, PlaceRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceRatingQuery, PlaceRatingQueryVariables>(PlaceRatingDocument, options);
        }
export type PlaceRatingQueryHookResult = ReturnType<typeof usePlaceRatingQuery>;
export type PlaceRatingLazyQueryHookResult = ReturnType<typeof usePlaceRatingLazyQuery>;
export type PlaceRatingQueryResult = Apollo.QueryResult<PlaceRatingQuery, PlaceRatingQueryVariables>;
export const PublicFilterPropertiesDocument = gql`
    query publicFilterProperties($filter: PublicFilterPropertiesInput) {
  publicFilterProperties(filter: $filter) {
    ...PropertyListItem
  }
}
    ${PropertyListItemFragmentDoc}`;

/**
 * __usePublicFilterPropertiesQuery__
 *
 * To run a query within a React component, call `usePublicFilterPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicFilterPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicFilterPropertiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePublicFilterPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<PublicFilterPropertiesQuery, PublicFilterPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicFilterPropertiesQuery, PublicFilterPropertiesQueryVariables>(PublicFilterPropertiesDocument, options);
      }
export function usePublicFilterPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicFilterPropertiesQuery, PublicFilterPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicFilterPropertiesQuery, PublicFilterPropertiesQueryVariables>(PublicFilterPropertiesDocument, options);
        }
export type PublicFilterPropertiesQueryHookResult = ReturnType<typeof usePublicFilterPropertiesQuery>;
export type PublicFilterPropertiesLazyQueryHookResult = ReturnType<typeof usePublicFilterPropertiesLazyQuery>;
export type PublicFilterPropertiesQueryResult = Apollo.QueryResult<PublicFilterPropertiesQuery, PublicFilterPropertiesQueryVariables>;
export const CreateHeyLeadDocument = gql`
    mutation createHeyLead($input: HeyLeadCreateInput!) {
  createHeyLead(input: $input) {
    fullName
    companyEmail
    companyPhone
    desksFrom
    desksTo
    locations
    budgetFrom
    budgetTo
    selectedOffices
  }
}
    `;
export type CreateHeyLeadMutationFn = Apollo.MutationFunction<CreateHeyLeadMutation, CreateHeyLeadMutationVariables>;

/**
 * __useCreateHeyLeadMutation__
 *
 * To run a mutation, you first call `useCreateHeyLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHeyLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHeyLeadMutation, { data, loading, error }] = useCreateHeyLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHeyLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateHeyLeadMutation, CreateHeyLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHeyLeadMutation, CreateHeyLeadMutationVariables>(CreateHeyLeadDocument, options);
      }
export type CreateHeyLeadMutationHookResult = ReturnType<typeof useCreateHeyLeadMutation>;
export type CreateHeyLeadMutationResult = Apollo.MutationResult<CreateHeyLeadMutation>;
export type CreateHeyLeadMutationOptions = Apollo.BaseMutationOptions<CreateHeyLeadMutation, CreateHeyLeadMutationVariables>;
export const OfficeSearchDataAmenitiesDocument = gql`
    query officeSearchDataAmenities {
  officeSearchData {
    amenities {
      id
      name
    }
  }
}
    `;

/**
 * __useOfficeSearchDataAmenitiesQuery__
 *
 * To run a query within a React component, call `useOfficeSearchDataAmenitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeSearchDataAmenitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeSearchDataAmenitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfficeSearchDataAmenitiesQuery(baseOptions?: Apollo.QueryHookOptions<OfficeSearchDataAmenitiesQuery, OfficeSearchDataAmenitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficeSearchDataAmenitiesQuery, OfficeSearchDataAmenitiesQueryVariables>(OfficeSearchDataAmenitiesDocument, options);
      }
export function useOfficeSearchDataAmenitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficeSearchDataAmenitiesQuery, OfficeSearchDataAmenitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficeSearchDataAmenitiesQuery, OfficeSearchDataAmenitiesQueryVariables>(OfficeSearchDataAmenitiesDocument, options);
        }
export type OfficeSearchDataAmenitiesQueryHookResult = ReturnType<typeof useOfficeSearchDataAmenitiesQuery>;
export type OfficeSearchDataAmenitiesLazyQueryHookResult = ReturnType<typeof useOfficeSearchDataAmenitiesLazyQuery>;
export type OfficeSearchDataAmenitiesQueryResult = Apollo.QueryResult<OfficeSearchDataAmenitiesQuery, OfficeSearchDataAmenitiesQueryVariables>;
export const OfficeSearchDataRegionsDocument = gql`
    query officeSearchDataRegions {
  officeSearchData {
    regions {
      id
      name
      parentRegionId
    }
  }
}
    `;

/**
 * __useOfficeSearchDataRegionsQuery__
 *
 * To run a query within a React component, call `useOfficeSearchDataRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeSearchDataRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeSearchDataRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfficeSearchDataRegionsQuery(baseOptions?: Apollo.QueryHookOptions<OfficeSearchDataRegionsQuery, OfficeSearchDataRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficeSearchDataRegionsQuery, OfficeSearchDataRegionsQueryVariables>(OfficeSearchDataRegionsDocument, options);
      }
export function useOfficeSearchDataRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficeSearchDataRegionsQuery, OfficeSearchDataRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficeSearchDataRegionsQuery, OfficeSearchDataRegionsQueryVariables>(OfficeSearchDataRegionsDocument, options);
        }
export type OfficeSearchDataRegionsQueryHookResult = ReturnType<typeof useOfficeSearchDataRegionsQuery>;
export type OfficeSearchDataRegionsLazyQueryHookResult = ReturnType<typeof useOfficeSearchDataRegionsLazyQuery>;
export type OfficeSearchDataRegionsQueryResult = Apollo.QueryResult<OfficeSearchDataRegionsQuery, OfficeSearchDataRegionsQueryVariables>;
export const OfficeSearchDataProvidersDocument = gql`
    query officeSearchDataProviders($filter: OfficeFiltersInput) {
  officeSearchDataProviders(filter: $filter) {
    providers {
      id
      name
      propertiesCount
      officeCount
    }
  }
}
    `;

/**
 * __useOfficeSearchDataProvidersQuery__
 *
 * To run a query within a React component, call `useOfficeSearchDataProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeSearchDataProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeSearchDataProvidersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOfficeSearchDataProvidersQuery(baseOptions?: Apollo.QueryHookOptions<OfficeSearchDataProvidersQuery, OfficeSearchDataProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficeSearchDataProvidersQuery, OfficeSearchDataProvidersQueryVariables>(OfficeSearchDataProvidersDocument, options);
      }
export function useOfficeSearchDataProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficeSearchDataProvidersQuery, OfficeSearchDataProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficeSearchDataProvidersQuery, OfficeSearchDataProvidersQueryVariables>(OfficeSearchDataProvidersDocument, options);
        }
export type OfficeSearchDataProvidersQueryHookResult = ReturnType<typeof useOfficeSearchDataProvidersQuery>;
export type OfficeSearchDataProvidersLazyQueryHookResult = ReturnType<typeof useOfficeSearchDataProvidersLazyQuery>;
export type OfficeSearchDataProvidersQueryResult = Apollo.QueryResult<OfficeSearchDataProvidersQuery, OfficeSearchDataProvidersQueryVariables>;
export const OfficeSearchDataBudgetDocument = gql`
    query officeSearchDataBudget($input: OfficeSearchBudgetInput) {
  officeSearchDataBudget(input: $input) {
    avgPrice
    min
    max
    data {
      range {
        from
        to
      }
      propertiesCount
    }
  }
}
    `;

/**
 * __useOfficeSearchDataBudgetQuery__
 *
 * To run a query within a React component, call `useOfficeSearchDataBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeSearchDataBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeSearchDataBudgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfficeSearchDataBudgetQuery(baseOptions?: Apollo.QueryHookOptions<OfficeSearchDataBudgetQuery, OfficeSearchDataBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficeSearchDataBudgetQuery, OfficeSearchDataBudgetQueryVariables>(OfficeSearchDataBudgetDocument, options);
      }
export function useOfficeSearchDataBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficeSearchDataBudgetQuery, OfficeSearchDataBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficeSearchDataBudgetQuery, OfficeSearchDataBudgetQueryVariables>(OfficeSearchDataBudgetDocument, options);
        }
export type OfficeSearchDataBudgetQueryHookResult = ReturnType<typeof useOfficeSearchDataBudgetQuery>;
export type OfficeSearchDataBudgetLazyQueryHookResult = ReturnType<typeof useOfficeSearchDataBudgetLazyQuery>;
export type OfficeSearchDataBudgetQueryResult = Apollo.QueryResult<OfficeSearchDataBudgetQuery, OfficeSearchDataBudgetQueryVariables>;
export const PublicPropertiesDocument = gql`
    query publicProperties($publicPropertiesInput: PublicPropertiesInput) {
  publicProperties(publicPropertiesInput: $publicPropertiesInput) {
    ...PropertiesListItem
  }
}
    ${PropertiesListItemFragmentDoc}`;

/**
 * __usePublicPropertiesQuery__
 *
 * To run a query within a React component, call `usePublicPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPropertiesQuery({
 *   variables: {
 *      publicPropertiesInput: // value for 'publicPropertiesInput'
 *   },
 * });
 */
export function usePublicPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<PublicPropertiesQuery, PublicPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicPropertiesQuery, PublicPropertiesQueryVariables>(PublicPropertiesDocument, options);
      }
export function usePublicPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicPropertiesQuery, PublicPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicPropertiesQuery, PublicPropertiesQueryVariables>(PublicPropertiesDocument, options);
        }
export type PublicPropertiesQueryHookResult = ReturnType<typeof usePublicPropertiesQuery>;
export type PublicPropertiesLazyQueryHookResult = ReturnType<typeof usePublicPropertiesLazyQuery>;
export type PublicPropertiesQueryResult = Apollo.QueryResult<PublicPropertiesQuery, PublicPropertiesQueryVariables>;
export const PublicPropertyDocument = gql`
    query publicProperty($publicPropertyInput: PublicPropertyInput) {
  publicProperty(publicPropertyInput: $publicPropertyInput) {
    ...PropertyItem
  }
}
    ${PropertyItemFragmentDoc}`;

/**
 * __usePublicPropertyQuery__
 *
 * To run a query within a React component, call `usePublicPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPropertyQuery({
 *   variables: {
 *      publicPropertyInput: // value for 'publicPropertyInput'
 *   },
 * });
 */
export function usePublicPropertyQuery(baseOptions?: Apollo.QueryHookOptions<PublicPropertyQuery, PublicPropertyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicPropertyQuery, PublicPropertyQueryVariables>(PublicPropertyDocument, options);
      }
export function usePublicPropertyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicPropertyQuery, PublicPropertyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicPropertyQuery, PublicPropertyQueryVariables>(PublicPropertyDocument, options);
        }
export type PublicPropertyQueryHookResult = ReturnType<typeof usePublicPropertyQuery>;
export type PublicPropertyLazyQueryHookResult = ReturnType<typeof usePublicPropertyLazyQuery>;
export type PublicPropertyQueryResult = Apollo.QueryResult<PublicPropertyQuery, PublicPropertyQueryVariables>;
export const PublicPropertyShortenDocument = gql`
    query publicPropertyShorten($publicPropertyInput: PublicPropertyInput) {
  publicProperty(publicPropertyInput: $publicPropertyInput) {
    ...PropertyShortenItem
  }
}
    ${PropertyShortenItemFragmentDoc}`;

/**
 * __usePublicPropertyShortenQuery__
 *
 * To run a query within a React component, call `usePublicPropertyShortenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPropertyShortenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPropertyShortenQuery({
 *   variables: {
 *      publicPropertyInput: // value for 'publicPropertyInput'
 *   },
 * });
 */
export function usePublicPropertyShortenQuery(baseOptions?: Apollo.QueryHookOptions<PublicPropertyShortenQuery, PublicPropertyShortenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicPropertyShortenQuery, PublicPropertyShortenQueryVariables>(PublicPropertyShortenDocument, options);
      }
export function usePublicPropertyShortenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicPropertyShortenQuery, PublicPropertyShortenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicPropertyShortenQuery, PublicPropertyShortenQueryVariables>(PublicPropertyShortenDocument, options);
        }
export type PublicPropertyShortenQueryHookResult = ReturnType<typeof usePublicPropertyShortenQuery>;
export type PublicPropertyShortenLazyQueryHookResult = ReturnType<typeof usePublicPropertyShortenLazyQuery>;
export type PublicPropertyShortenQueryResult = Apollo.QueryResult<PublicPropertyShortenQuery, PublicPropertyShortenQueryVariables>;
export const MapRegionsDocument = gql`
    query mapRegions {
  regions {
    ...RegionItem
  }
}
    ${RegionItemFragmentDoc}`;

/**
 * __useMapRegionsQuery__
 *
 * To run a query within a React component, call `useMapRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMapRegionsQuery(baseOptions?: Apollo.QueryHookOptions<MapRegionsQuery, MapRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MapRegionsQuery, MapRegionsQueryVariables>(MapRegionsDocument, options);
      }
export function useMapRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MapRegionsQuery, MapRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MapRegionsQuery, MapRegionsQueryVariables>(MapRegionsDocument, options);
        }
export type MapRegionsQueryHookResult = ReturnType<typeof useMapRegionsQuery>;
export type MapRegionsLazyQueryHookResult = ReturnType<typeof useMapRegionsLazyQuery>;
export type MapRegionsQueryResult = Apollo.QueryResult<MapRegionsQuery, MapRegionsQueryVariables>;