import React, { Fragment, useCallback, useContext, useRef } from 'react';
import { Box, Button as MuiButton, Card, IconButton, Popover } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Favorite } from '@material-ui/icons';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'src/shared/components/Button/Button';
import Text from 'src/shared/components/Text/Text';
import { openContactOfficeDialog, shortlistProperties, unshortlistProperty } from 'src/store/actions';
import { AppContext } from 'src/store/store';
import { PropertyContainer } from './PropertyContainer';

const FavouriteIcon = styled(({ active, ...restProps }) => (
    <Favorite {...restProps} />
))(({ active = false }: { active?: boolean }) => ({
    stroke: 'black',
    strokeWidth: '2px',
    fill: active ? '#ffea00' : 'white',
    fontSize: '2.5rem',
}));

const FavouritesButton = styled(IconButton)({
    zIndex: 1301,
});

const OfficeCounter = styled(Text)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -60%)',
});

const CardStyled = styled(Card)({
    width: '350px',
    maxHeight: 'calc(75vh - 2rem)',
    padding: '1rem 1rem 0',
    overflow: 'hidden auto',
});

function Favourites() {
    const { state: { shortlistedProperties }, dispatch } = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const officeCount = shortlistedProperties.reduce((acc, property) => acc + property.office.length, 0);
    const containerRef = useRef(null);
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'favouritesPopover',
    });

    const clearShortlistedProperties = () => {
        dispatch(shortlistProperties([]));
        popupState.close();
    };

    const handleCompareClick = () => {
        localStorage.setItem('savedSearch', location.search.slice(1));
        history.push('/compare');
    };

    const handleBookViewingsClick = () => dispatch(openContactOfficeDialog());

    const handleUnshortlistProperty = useCallback((propertyId: string) => (
        () => dispatch(unshortlistProperty({ propertyId }))
    ), [dispatch]);

    return (
        <Box {...{ ref: containerRef }}>
            <FavouritesButton {...bindTrigger(popupState)} id="resultsenquire" title="See Your Favourites" size="small">
                {!!officeCount && <OfficeCounter size="0.875rem" sizeMd="1rem" bold>{officeCount}</OfficeCounter>}
                <FavouriteIcon active={!!officeCount} />
            </FavouritesButton>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                container={containerRef.current}
            >
                <CardStyled>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb="1rem">
                        <Text size="1.5rem">Favourites</Text>
                        {!!shortlistedProperties.length && (
                            <MuiButton variant="text" color="primary" onClick={clearShortlistedProperties}>
                                Clear
                            </MuiButton>
                        )}
                    </Box>
                    <Box height="100%">
                        {shortlistedProperties.map((property) => (
                            <PropertyContainer
                                key={property.id}
                                property={property}
                                onUnshortlistProperty={handleUnshortlistProperty}
                            />
                        ))}
                    </Box>
                    {!shortlistedProperties.length && (
                        <Fragment>
                            <Text size="1.125rem" mb="1rem" bold>Found an office you like?</Text>
                            <Text size="1.125rem" mb="1rem">
                                Click the heart icon on specific offices in order to save and compare them.
                            </Text>
                            <Text size="1.125rem" mb="1rem">
                                Once you have favourited an office, you’ll see them listed here.
                            </Text>
                        </Fragment>
                    )}
                </CardStyled>
                {!!shortlistedProperties.length && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        p="1rem"
                        mt="-1rem"
                        bgcolor="white"
                        position="sticky"
                        zIndex={1}
                    >
                        <Button
                            id="favquicklook-compare"
                            size="small"
                            fontSize="1rem"
                            onClick={handleCompareClick}
                        >
                            Compare
                        </Button>
                        <Button
                            id="favquicklook-bookviewings"
                            size="small"
                            fontSize="1rem"
                            onClick={handleBookViewingsClick}
                        >
                            Book Viewings
                        </Button>
                    </Box>
                )}
            </Popover>
        </Box>
    );
}

export default Favourites;
