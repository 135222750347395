import React from 'react';
import { Grid } from '@material-ui/core';

import { ReactComponent as PlaceRoundIcon } from 'src/assets/images/PlaceRound.svg';
import Text from 'src/shared/components/Text/Text';

export const Location = ({ location }: { location: string }) => (
    <Grid container alignItems="center" wrap="nowrap" spacing={1}>
        <Grid item>
            <PlaceRoundIcon height="12" width="12" />
        </Grid>
        <Grid item>
            <Text>{location}</Text>
        </Grid>
    </Grid>
);
