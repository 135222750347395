import React, { ReactNode } from 'react';
import { Popover } from '@material-ui/core';
import Text from 'src/shared/components/Text/Text';
import { makeStyles, styled } from '@material-ui/core/styles';

const useStyles = makeStyles({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
        padding: '15px',
        maxWidth: '300px',
    },
});

const BetaTagControl = styled('div')({
    padding: '1px 5px',
    background: '#3344fe',
    borderRadius: '15px',
    width: '40px',
    textAlign: 'center',
    marginLeft: '1rem',
    '&:hover': {
        cursor: 'pointer'
    }
});

type BetaTagProps = {
    action: ReactNode;
}

export const BetaTag = ({ action }: BetaTagProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorEl = React.useRef(null);

    const popoverEnter = () => {
        setOpen(true);
    };

    const popoverLeave = () => {
        setOpen(false);
    };

    return (
        <div>
            <BetaTagControl
                ref={anchorEl}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
            >
                <Text size="0.625rem" uppercase color="#fff" bold>Beta</Text>
            </BetaTagControl>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
                disableRestoreFocus
            >
                <div>
                    <Text component="h2" bold mb="1rem">
                        BETA - What&#39;s this?
                    </Text>
                    <Text mb="1rem">
                        We&#39;re at an early stage of our product development, and we&#39;re constantly
                        iterating and improving the user experience and visual design of HeyOffices
                    </Text>
                    <Text mb="1rem">
                        We&#39;d love your feedback on anything you&#39;ve found helpful or frustrating
                    </Text>
                    {action}
                </div>
            </Popover>
        </div>
    );
};
