import React, { createContext, useReducer } from 'react';

import { PropertyItemFragment } from 'src/generated/graphql';
import reducer from './reducer';

export type State = {
    shortlistedProperties: PropertyItemFragment[];
    searchOverlay: boolean;
    listOverlay: boolean;
    noResultsOverlay: boolean;
    howItWorksDialog: boolean;
    hoveredPropertyCardId: string | null;
    contactOfficeDialog: {
        property?: PropertyItemFragment;
        officeId?: string | null;
    } | null;
}

type Context = {
    state: State;
    dispatch: React.Dispatch<any>;
}

const initialState: Context = {
    state: {
        shortlistedProperties: [],
        searchOverlay: false,
        listOverlay: false,
        noResultsOverlay: false,
        howItWorksDialog: false,
        hoveredPropertyCardId: null,
        contactOfficeDialog: null,
    },
    dispatch: () => null,
};

export const AppContext = createContext(initialState);

type StoreProps = {
    children: React.ReactNode;
}

export const Store = ({ children }: StoreProps) => {
    const [state, dispatch] = useReducer(reducer, initialState.state);

    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
