import React, { useState, ChangeEvent } from "react";
import { Grid, Button, TextField, InputAdornment } from '@material-ui/core';

type FilterProps = {
    onFilterUpdate: (term: string) => void;
    placeholder: string;
}

export const Filter = ({ onFilterUpdate, placeholder }: FilterProps) => {
    const [filterTerm, setFilterTerm] = useState<string>("");

    const handleClear = () => {
        setFilterTerm("");
        onFilterUpdate("");
    };

    const handleFilterUpdate = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilterTerm(event.target.value || "");
        onFilterUpdate(event.target.value || "");
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={12}>
                <TextField
                    name="filterTerm"
                    onChange={handleFilterUpdate}
                    value={filterTerm}
                    variant="outlined"
                    size="small"
                    placeholder={placeholder}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    variant="contained"
                                    onClick={handleClear}
                                    size="small"
                                    color="primary"
                                >
                                    Clear
                                </Button>
                                {' '}

                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    );
};
