import React, { ReactElement, Ref, forwardRef } from 'react';
import { Dialog, Grid, IconButton, Paper, Slide } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { HighlightOff } from '@material-ui/icons';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { ReactComponent as HeyOfficesLogo } from 'src/assets/images/HeyOfficesLogo.svg';
import { TextButton } from 'src/shared/components/TextButton/TextButton';

const Container = styled(Grid)({
    justifyContent: 'center',
    padding: '0.5rem 1rem 4rem',
    backgroundColor: 'black',
});

const Transition = forwardRef((props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
));

const PaperStyled = styled(Paper)({
    position: 'absolute',
    top: 0,
    width: '100%',
    margin: 0,
    overflow: 'hidden',
    borderRadius: 0,
});

const DialogStyled = styled(Dialog)({
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
});

const InvertedHeader = styled(Grid)({
    filter: 'invert(1)',
});

type MobileMenuProps = {
    open: boolean;
    onClose: () => void;
    button: ReactElement;
}

export const MobileMenu = ({ open, onClose, button }: MobileMenuProps) => {
    const location = useLocation();

    return (
        <DialogStyled open={open} PaperComponent={PaperStyled} TransitionComponent={Transition} onClose={onClose} maxWidth="md">
            <Container container alignItems="center" spacing={2}>
                <InvertedHeader item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <RouterLink to="/">
                                <HeyOfficesLogo height={32} />
                            </RouterLink>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={onClose} color="primary" edge="end">
                                <HighlightOff fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </InvertedHeader>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                        <TextButton
                            id="menuaboutus"
                            to="/about"
                            buttonText="About us"
                            hovered={!!matchPath(location.pathname, { path: '/about' })}
                            size="1.5rem"
                            inverted
                        />
                    </Grid>
                    <Grid item>
                        <TextButton
                            id="menufaqs"
                            to="/faq"
                            buttonText="FAQs"
                            hovered={!!matchPath(location.pathname, { path: '/faq' })}
                            size="1.5rem"
                            inverted
                        />
                    </Grid>
                    <Grid item>{button}</Grid>
                </Grid>
            </Container>
        </DialogStyled>
    );
};
