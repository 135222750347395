import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import { SnackbarProvider } from 'notistack';
import {
    createStyles,
    jssPreset,
    makeStyles,
    StylesProvider,
    ThemeProvider
} from '@material-ui/core';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import { createTheme, THEME } from 'src/theme';
import Routes from 'src/Routes';
import { Store } from 'src/store/store';
import { SearchDialog } from 'src/components/SearchDialog/SearchDialog';
import { ListDialog } from 'src/components/ListDialog/ListDialog';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins] });

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%'
        },
        '#root': {
            height: '100%',
            width: '100%'
        }
    }
}));

function App() {
    useStyles();
    const theme = createTheme({ theme: THEME.HEYOFFICE });

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <Router history={history}>
                    <SnackbarProvider maxSnack={3}>
                        <Store>
                            <ScrollReset />
                            <GoogleAnalytics />
                            <CookiesNotification />
                            <SearchDialog />
                            <ListDialog />
                            <Routes />
                        </Store>
                    </SnackbarProvider>
                </Router>
            </StylesProvider>
        </ThemeProvider>
    );
}

export default App;
