import { Media } from 'src/generated/graphql';
import { MediaPrefix, serveMedia } from './media';

export const getFeatureImage = (featureImage?: Media | null, images?: Media[] | null, prefix?: MediaPrefix) => {
    if (!featureImage && !images) {
        return '';
    }
    const mediaImage = images && images.length > 0 ? images[0].location : "";
    const feature = featureImage?.location ? featureImage?.location : mediaImage;
    return serveMedia(feature, prefix);
};
