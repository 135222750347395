type NavItem = {
    title: string;
    path: string;
    id: string;
};

export const navItems: NavItem[] = [
    { title: 'About Us', path: '/about', id: 'hpaboutus' },
    { title: 'FAQs', path: '/faq', id: 'hpfaqs' },
];
