import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export const getCookie = (name: string) => Cookies.get(name);

export const setCookie = (name: string, value: string) => Cookies.set(name, value);

export const setSessionCookie = (): string => {
    const id = uuidv4();
    setCookie("hoi", id);
    return id;
};

export const getSessionCookie = (): string => {
    const id = getCookie("hoi");
    return id || setSessionCookie();
};
