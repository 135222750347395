import React from 'react';
import { Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import Text from 'src/shared/components/Text/Text';

const TextButtonComponent = styled(({ to, onClick, hovered, inverted, ...restProps }) => (
    to ? <RouterLink to={to} {...restProps} /> : <Button onClick={onClick} {...restProps} />
))(({ hovered = false }: { hovered?: boolean }) => ({
    border: 0,
    borderRadius: 0,
    display: 'block',
    textDecoration: 'none',
    padding: '6px 8px',
    transition: 'background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: hovered ? '#FFEA00' : 'initial',
    '&:hover': {
        backgroundColor: '#FFEA00',
    },
}));

type TextButtonProps = {
    buttonText: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    to?: string;
    hovered?: boolean;
    id: string;
    size?: string;
    inverted?: boolean;
}

export const TextButton = ({
    buttonText,
    onClick,
    to,
    hovered,
    id,
    size,
    inverted,
}: TextButtonProps) => (
    <TextButtonComponent id={id} onClick={onClick} to={to} hovered={hovered} inverted={inverted}>
        <Text size={size || '0.875rem'} color={!inverted || hovered ? 'black' : 'white'} bold>{buttonText}</Text>
    </TextButtonComponent>
);
