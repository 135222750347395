import React, { ReactNode, ChangeEvent } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';

import Text from 'src/shared/components/Text/Text';

const AccordionStyled = styled(({ width, ...restProps }) => (
    <Accordion {...restProps} />
))(({ width }: { width?: string }) => ({
    boxShadow: 'none',
    marginBottom: '1rem',
    width: width || 'auto',
    '&.Mui-expanded:last-child': {
        marginBottom: '1rem',
    },
    '&::before': {
        content: 'none',
    },
}));

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
    minHeight: 'unset',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: 0,
    '&.Mui-expanded': {
        minHeight: 'unset',
    },
    '& > .MuiAccordionSummary-content': {
        margin: 0,
    },
}));

const AccordionDetailsStyled = styled(AccordionDetails)({
    padding: 0,
});

const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
    margin: '-11px',
    color: theme.palette.primary.main,
}));

type DropDownProps = {
    title: string;
    checked: boolean;
    onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
    children: ReactNode;
    defaultExpanded?: boolean;
    width?: string;
}

export const DropDown = ({ title, checked, onSelect, children, defaultExpanded, width }: DropDownProps) => (
    <AccordionStyled TransitionProps={{ timeout: 0 }} defaultExpanded={defaultExpanded} width={width} square>
        <AccordionSummaryStyled expandIcon={<ArrowDropDown color="primary" />} IconButtonProps={{ size: 'small' }}>
            <CheckboxStyled
                name={title}
                checked={checked}
                color="default"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={onSelect}
            />
            <Text size="0.875rem" ml="1rem" bold uppercase>{title}</Text>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
    </AccordionStyled>
);
